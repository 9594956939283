<template>
  <div class="icons">
    <div class="buttons">
      <router-link to="/auth/login" class="login">Login</router-link>
      <button class="btnme"><router-link to="/auth/singup" class="link-btn">Register</router-link></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileRegistrNav"
}
</script>

<style scoped>

.buttons {
  border: 0;
  height: 46px;
  background: rgba(30, 42, 75, 0.6);
  border-radius: 10px;
  flex-grow: 3;
  justify-content: space-around;
  display: flex;
  align-items: center;
  order: 1;
  margin: 10px;
}

.icons {
  display: flex;
  flex-grow: 2;
  align-items: center;
  justify-content: flex-end;

}

.login {
  line-height: 2.5;
  color: #fcfcfc;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.login:hover {
  color: #ff5107;
  text-decoration: none;
}

.btnme {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #ff5107;
  color: #ff5107;
  height: 36px;
  flex-basis: 150px;
  margin: 0 10px;

}

.link-btn{
  text-decoration: none;
  color: #FF5107;
  font-size: 16px;
}

.btnme:hover {
  background-color: #ff4c0091;
  color: white;
}
</style>