<template>
  <the-modal @close="$emit('close')">
    <div>
      <div class="header">
        <span>Bind Phone Number</span>
      </div>
      <TheInput logo="email" placeholder="Phone Number" type="email"/>
      <div class="modal-btn">
        <button @click="show =!show" class="modalBtn btn btn-block">Send OTP</button>
      </div>

      <div class="entercode">
        <span class="spn-enter-code">Enter OTP</span>
      </div>

      <div class="codinput">
        <TheCodeInput />
      </div>

    </div>
  </the-modal>
</template>

<script>
import TheInput from "../../Tools/TheInput";
import TheModal from "../../Tools/TheModal";
import TheCodeInput from "../../Tools/TheCodeInput";

export default {
  data(){
    return{
      show : false
    }
  },
  name: "EmailModalpass",
  components: {TheModal, TheInput , TheCodeInput},
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.header span {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.modal-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBtn{
  background: #FF5107;
  border-radius: 7px;
  border: 0;
  padding: 10px;
  color: white;
  font-size: 14px;}

.entercode{
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.spn-enter-code{
  font-size: 16px;
  color: #756F6F;
}
.codinput{
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;

}
</style>