<template>
  <div class="main-forget-form">
    <header-form/>
    <the-input type="email" placeholder="Email" logo="email"/>
    <IAmNotRobot/>
    <TheButtonForm value="Get Reset Code"/>
  </div>
</template>

<script>
import HeaderForm from "../../Tools/HeaderForm";
import TheInput from "../../Tools/TheInput";
import IAmNotRobot from "../../Tools/IAmNotRobot";
import TheButtonForm from "../../Tools/TheButtonForm";
export default {
  name: "ForgetPassWord",
  components:{TheButtonForm, IAmNotRobot, TheInput, HeaderForm}
}
</script>

<style scoped>
.main-forget-form{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>