<template>
  <div class="main-item">
    <div class="large-pic">
      <img src="../../assets/galery/bg-bitcoin.png" alt="">
      <TheButtonGallery class="position" width="100" height="80" value="Is day trading crypto worth it?"/>
      <TheButtonGallery class="position2" width="32" height="30" value="Trading Guide" logo="star" :showimg="true" rate="4.5"/>
      <TheButtonGallery class="position3" width="32" height="30" value="Sat 22/5" logo="calendar" :showimg="false"/>
    </div>

    <div class="grid-con">
      <div class="item1">
        <img src="../../assets/galery/crypto-currency-coinbig.png" alt="">
        <TheButtonGallery class="position" width="100" height="80" value="Is day trading crypto worth it?"/>
        <img class="star" src="../../assets/galery/star.png" alt="">
      </div>
      <div class="item2">
        <img src="../../assets/galery/crypto-currency-coin_small.png" alt="">
        <TheButtonGallery class="position" width="100" height="30" value="Is day"/>
        <img class="star" src="../../assets/galery/star.png" alt="">
      </div>
      <div class="item2">
        <img src="../../assets/galery/crypto-currency-coin_small.png" alt="">
        <TheButtonGallery class="position" width="100" height="30" value="Is day"/>
        <img class="star" src="../../assets/galery/star.png" alt="">
      </div>
      <div class="item3">
        <img src="../../assets/galery/crypto-currency-coin_small.png" alt="">
        <TheButtonGallery class="position" width="100" height="30" value="Is day"/>
        <img class="star" src="../../assets/galery/star.png" alt="">
      </div>
      <div class="item4">
        <img src="../../assets/galery/crypto-currency-coin_small.png" alt="">
        <TheButtonGallery class="position" width="100" height="30" value="Is day"/>
        <img class="star" src="../../assets/galery/star.png" alt="">
      </div>
      <div class="item5">
        <img src="../../assets/galery/crypto-currency-coin_small.png" alt="">
        <a href="#" class="more-spn">More +</a>
      </div>
    </div>
    <div class="row-flex">
      <button class="btnme">Visit Warex Blog</button>
    </div>
  </div>
</template>

<script>
import TheButtonGallery from "../Tools/TheButtonGallery";

export default {
  name: "galery",
  components: {TheButtonGallery},
}
</script>

<style scoped>
.more-spn{
  display: inline;
  position: absolute;
  left: 35%;
  top: 35%;
  font-size: 1.7rem;
  color: #5C5C63;
  text-decoration: none;
}

.star{
  position: absolute;
  width: 10% !important;
  float: left;
  right: 87%;
  top: 2%;
}

.item1 , .item2 ,.item3 ,.item4, .item5{
  position: relative;
}

.item5{
  opacity: 0.7;
}

.position {
  position: absolute;
  top: 30%;
  max-width: 70%;
  left: 12%;
}

.position2 {
  position: absolute;
  top: 2%;
  left: 2%;
}

.position3 {
  position: absolute;
  top: 85%;
  max-width: 70%;
  left: 3%;
}

.row-flex {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-top: 50px;
}

.btnme {
  background: linear-gradient(98.51deg, #1CBFE2 7.26%, #0F74EB 118.38%);
  border-radius: 7px;
  color: white;
  font-size: 14px;
  border: 0;
  padding: 10px 20px;
}

.btnme:hover {
  background: linear-gradient(98.51deg, #0F74EB -1.56%, #1CBFE2 110.22%);
}

.large-pic {
  display: grid;
  max-width: 100%;
  position: relative;
}

.large-pic img {
  width: 100%;
}


.grid-con {
  display: grid;
  grid-gap: 3px;
  grid-template-columns: auto auto auto;
}

.item1 {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

.main-item {
  width: 75%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.grid-con img {
  width: 100%;
}
</style>