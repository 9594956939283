<template>
  <div class="bode-cy">
  <div class="main-crypto">
    <div class="tittle">
      <h3>Your Crypto</h3>
    </div>
    <div class="wrape"></div>
    <TheWallet v-for="(item,index) in data" :item="item" :key="index.name" @withdraw="withdraw" />
    <WalletModal v-if="modal" :item="item" @close="modal = false"/>
  </div>
  </div>
</template>

<script>
import TheWallet from "../Tools/TheWallet";
import WalletModal from "./WalletModal";

export default {
  name: "Crypto",
  components: {WalletModal, TheWallet},
  data() {
    return {
      modal : false,
      item:{},
      data: [
        {
          name: 'Bitcoin',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'bitcoin'
        },

        {
          name: 'Etherum',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'etherum'
        },

        {
          name: 'Ripple',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'ripple'
        },
        {
          name: 'Bitcoin',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'bitcoin'
        },

        {
          name: 'Etherum',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'etherum'
        },

        {
          name: 'Ripple',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'ripple'
        },
        {
          name: 'Bitcoin',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'bitcoin'
        },

        {
          name: 'Etherum',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'etherum'
        },

        {
          name: 'Ripple',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'ripple'
        },
      ]
    }
  },

  methods:{
    withdraw:function ($event){
     this.item = $event;
     this.modal = true
    }
  }
}
</script>

<style scoped>
.main-crypto{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #1E2A4B;
  width: 90%;
  border-radius: 16px;
  margin: 20px 0;
  padding: 18px;

}

.bode-cy{
  background-color: #081734;
  justify-content: center;
  align-items: center;
  display: flex;
}

.spn-tittle{
  font-size: 20px;
  color: white;
}

.tittle {
  display: flex;
  justify-content: flex-start;
  color: white;
}

.wrape{
  margin-bottom: 50px;
  width: 100%;
}

</style>