<template>
  <div class="main">
    <div class="order-history">
      <div class="table">
        <div class="tittle">
          <h3>Login History</h3>
        </div>
        <table class="table table-borderless">
          <tr>
            <th>Time</th>
            <th>Location</th>
            <th v-if="width > 768">IP Address</th>
            <th>Browser</th>
            <th v-if="width > 768">Device</th>
            <th v-if="width > 768">Status</th>
          </tr>

          <tr v-for="d in data" :key="d[0]">
            <td>{{ d.Time }}</td>
            <td>{{ d.Location }}</td>
            <td v-if="width > 768">{{ d.IPAddress }}</td>
            <td>{{ d.Browser }}</td>
            <td v-if="width > 768">{{ d.Device }}</td>
            <td v-if="width > 768" v-show="d.Status === true">
              <img src="../../assets/History/success-chart.png" alt="">
            </td>
            <td v-if="width > 768" v-show="d.Status === false">
              <img src="../../assets/History/block.png" alt="">
            </td>
          </tr>

        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginHistory",
  data() {
    return {
      width: 0,
      data: [
        {
          Location: 'US',
          IPAddress: '138.68.44.146',
          Time: '4/5/21  05:12',
          Browser: 'Chrome',
          Device: 'Desktop',
          Status: true
        },

        {
          Location: 'US',
          IPAddress: '138.68.44.146',
          Time: '4/5/21  05:12',
          Browser: 'Chrome',
          Device: 'Desktop',
          Status: true
        },

        {
          Location: 'US',
          IPAddress: '138.68.44.146',
          Time: '4/5/21  05:12',
          Browser: 'Chrome',
          Device: 'Desktop',
          Status: false
        },

        {
          Location: 'US',
          IPAddress: '138.68.44.146',
          Time: '4/5/21  05:12',
          Browser: 'Chrome',
          Device: 'Desktop',
          Status: true
        },

        {
          Location: 'US',
          IPAddress: '138.68.44.146',
          Time: '4/5/21  05:12',
          Browser: 'Chrome',
          Device: 'Desktop',
          Status: false
        },

        {
          Location: 'US',
          IPAddress: '138.68.44.146',
          Time: '4/5/21  05:12',
          Browser: 'Chrome',
          Device: 'Desktop',
          Status: true
        },

        {
          Location: 'US',
          IPAddress: '138.68.44.146',
          Time: '4/5/21  05:12',
          Browser: 'Chrome',
          Device: 'Desktop',
          Status: false
        },

        {
          Location: 'US',
          IPAddress: '138.68.44.146',
          Time: '4/5/21  05:12',
          Browser: 'Chrome',
          Device: 'Desktop',
          Status: true
        },
      ],

      options: {
        colors: ['#1CFF77'],
        dataLabels: {
          enabled: false,
        },

        chart: {
          offsetX: -30
        }

      },
      series: [100],
    }
  },

  "mounted"() {
    this.width = screen.width
  },
}
</script>

<style scoped>
.main {
  display: flex;
  justify-content: center;
  background-color: #040F26;
  align-items: center;
}

.order-history {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #1E2A4B;
  width: 80%;
  border-radius: 16px;
  margin: 50px 0;
}

.table {
  width: 90%;
  max-width: 100%;
  background-color: transparent;
  margin: 30px auto;
  color: white;
}

.tittle{
  display: flex;
  justify-content: flex-start;
  margin-left: 5%;
}

table {
  background-color: transparent !important;
  color: white;
  font-size: 12px;
  position: relative;
  table-layout: fixed;
  margin-top: 50px;
}

table th {
  border: none;
  color: #EDEDED !important;
  font-weight: 100;
  padding: 0;
}

.table td {
  border: none;
}


.table th, .table td {
  vertical-align: middle;
  padding: 5px !important;
}

@media (max-device-width: 420px) {
  .filter {
    justify-content: flex-start;
  }
}

</style>