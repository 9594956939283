<template>
    <button :style="{width:width+'%' , height:height + 'px'}" class="btnlight">
      <img  :src="require('../../assets/galery/'+icon)"  v-if="showimg" class="star" alt="">
      {{ value }}
    </button>
</template>

<script>
const  a = {
  star:'star.png',
  calendar : 'calendar.png',
}
export default {
  props: ['width', 'height', 'value' , 'showimg' , 'rate' , 'logo'],
  name: "TheButtonGallery",
  computed:{
    icon(){
      return a[this.logo]
    }
  },
}
</script>

<style scoped>
.btnlight {
  background: rgba(255, 255, 255, 0.68);
  border-radius: 58px;
  border: 0;
  font-size: 1.3rem;
}

.star{
  position: absolute;
  top: 7%;
  max-width: 70%;
  left: 3%;
  width: 15% !important;
}

@media only screen and (max-width: 490px){
  .star{
    display: none;
  }
}

</style>