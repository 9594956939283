<template>
  <div class="main-verify">
    <div class="padding">
      <div class="left">
        <router-view/>
      </div>
      <Thetolbar/>
    </div>
  </div>
</template>

<script>
import Thetolbar from "../Tools/Thetolbar";

export default {
  name: "Veryfication",
  components: {Thetolbar},

}
</script>

<style scoped>
.main-verify {
  background-color: #040F26;
  display: flex;
  color: white;
  flex-wrap: wrap;
}

.left {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 2;
  flex-basis: 300px;
}


.padding{
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: first baseline;
  width: 80%;
  flex-wrap: wrap;
  gap: 20px;
}


</style>