<template>
  <div class="right">
    <div class="top-txt">
      <p>How to sign up at Warex?</p>
      <img class="bottom-arrow" src="../../assets/KYC/bottom-arrow.png" alt="">
    </div>
    <div class="border"></div>
    <div class="bottom-txt">
      <p>How to sign up at Warex?</p>
      <img class="bottom-arrow" src="../../assets/KYC/bottom-arrow.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Thetolbar"
}
</script>

<style scoped>
.top-txt{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-size: 15px;
}

.bottom-txt{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  font-size: 15px;
}

.bottom-arrow{
  width: 16px;
  height: 10px;
  cursor: pointer;
}

.border{
  border: 1px solid rgba(241, 241, 241, 0.28);
}
.right {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 300px;
}
</style>