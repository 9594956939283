<template>
  <div class="container-flex">
    <div class="logo">
      <router-link to="/"><img src="../../../assets/Header/logoheader.png" alt="logoheader"/></router-link>
    </div>
    <div class="menu">
      <a href="#" class="link" v-for="l in links" :key="l">{{ l }}</a>
    </div>
    <div class="icons">
      <app-not-register-nav v-if="register"></app-not-register-nav>
      <app-register-nav v-if="!register"></app-register-nav>
      <div class="icon">

        <a>
          <img src="../../../assets/Header/bag.png" alt="">
        </a>

        <div class="line"></div>

        <div class="main-alert">
          <a @click="ShowAlert = !ShowAlert">
            <img  v-if="!alerts" src="../../../assets/Header/alert.png" alt="alert" class="linkmehover"/>
            <span   class="number-alert">4</span>
            <img v-if="alerts" src="../../../assets/Header/alert-red.svg" alt="alert" class="linkmehover"/>
          </a>
          <div v-if="ShowAlert" class="alert-me">
            <div class="header-alert">
              4 New Alerts
            </div>
            <div v-for="(alert ,index) in alerts" :key="index" class="alerts">
              <div class="row-1">{{alert.text}}</div>
              <div class="row-2">{{alert.date}}  {{alert.time}}</div>
            </div>
          </div>
        </div>

        <a href="#">
          <img src="../../../assets/Header/headphones.png" alt="headphones" class="linkmehover"/>
        </a>
        <a href="#">
          <img
              src="../../../assets/Header/earth.png"
              alt="headphones"
              class="linkmehover"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import RegisterNav from "./RegisterNav";
import NotRegisterNav from "./NotRegisterNav";

export default {
  name: "navbar",
  data() {
    return {
      links: ["Buy/Sell", "Fees", "Learn", "Help"],
      register: false,
      ShowAlert:false,
      alerts: [
        {text: 'Login attempted from new IP', date: '4/5/21', time: '05:12'},
        {text: 'Login attempted from new IP', date: '4/5/21', time: '05:12'},
        {text: 'Login attempted from new IP', date: '4/5/21', time: '05:12'},
        {text: 'Login attempted from new IP', date: '4/5/21', time: '05:12'}
      ]
    }
  },

  components: {
    appRegisterNav: RegisterNav,
    appNotRegisterNav: NotRegisterNav
  }

}
</script>

<style scoped>

.alerts {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.linkmehover{
  position: relative;
}


.number-alert{
  position: absolute;
  right: 3px;
  z-index: 1;
  bottom: 5px;
  color: white;
}

.row-1{
  display: flex;
  flex-grow: 1;
  font-size: medium;
  color: #635757;
}

.row-2{
  display: flex;
  flex-direction: row;
  font-size: small;
  color: #635757;
}
.container-flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  background-color: #040F26;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.menu {
  flex-grow: 6;
  flex-direction: row;
  display: flex;
  align-items: center;
}

.menu a {
  margin-right: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

}

.logo {
  flex-grow: 1;
}


.icons {
  flex-grow: 5;
  display: flex;
  align-items: center;
  gap: 40px;
}

.icons a{
  cursor: pointer;
}

.link {
  color: #fcfcfc;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.login {
  line-height: 2.5;
  color: #fcfcfc;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.login:hover {
  color: #ff5107;
  text-decoration: none;
}

.btnme {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #ff5107;
  color: #ff5107;
  flex-basis: 117px;
  height: 36px;

}

.btnme:hover {
  background-color: #ff4c0091;
  color: white;
}

.buttons {
  border: 0;
  height: 46px;
  background: rgba(30, 42, 75, 0.6);
  border-radius: 10px;
  flex-grow: 3;
  justify-content: space-around;
  display: flex;
  align-items: center;
  order: 1;
}

.icon {
  flex-grow: 3;
  display: flex;
  order: 0;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
}

.icon a {
  margin-right: 38px;
  position: relative;
}

.line {
  margin: 10px;
  position: relative;
}

.line::after {
  content: '';
  width: 1px;
  height: 40px;
  color: white;
  background-color: whitesmoke;
  position: absolute;
  right: 14px;
  top: -18px;
  bottom: 36px;
}

.main-alert {
  position: relative;
  z-index: 0;
}

.alert-me {
  position: absolute;
  z-index: 1;
  background-color: white;
  right: 65%;
  top: 150%;
  border-radius: 40px 0 20px 20px;
  width: 300px;
  padding: 20px;
}

.header-alert {
  color: #02163E;
  font-size: small;
  font-weight: bold;
}
</style>