<template>
  <div class="contact-us">
    <div class="item1">
      <h1>Contact us</h1>
    </div>
    <div class="item2">
      <img src="../../assets/About/phone.png" alt="">
      <p>
        Phone number:
      </p>
      <p>
        {{number}}
      </p>
    </div>
    <div class="item3">
      <img src="../../assets/About/address 1.png" alt="">
      <p>
        Company address:
        {{Address}}
      </p>
    </div>
    <div class="item4">
      <img src="../../assets/About/email.png" alt="">
      <p>
        Email:
      </p>
      <p>
        {{email}}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data(){
    return{
      number:'+98 226 798 4487',
      Address : '  Unit 10-02, Level 10, Menara Binjai, No. 2, Jalan Binjai, 50450 Kuala Lumpur, Malaysia',
      email : 'support@okex.com'
    }
  }
}
</script>

<style scoped>
img{
  margin-bottom: 20px;
}

p{
  font-size: 14px;
}
.contact-us {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  margin: 20px auto;
  align-items: baseline;
}

.item1 {
  flex-grow: 3;
  flex-basis: 100px;
  margin-bottom: 30px;
}

.item2 {
  flex-direction: column;
  flex-grow: 1;
  flex-basis:100px;
  margin: 0 30px 10px 0;
}



.item3 {
  flex-direction: column;
  flex-grow: 1;
  flex-basis:100px;
  margin: 0 30px 10px 0;
}

.item4 {
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 100px;
  margin: 0 30px 10px 0;
}

@media screen and (max-width: 992px) {
  .contact-us{
    flex-direction: column;
  }
}
</style>