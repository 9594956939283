<template>
  <div class="box-dashbord security">


    <change-password v-if="showpass" @close="showpass=false"/>
    <EmailModalpass v-if="showemail" @close="showemail=false"/>
    <sms-modal v-if="showsms" @close="showsms=false"/>
    <google-modal v-if="showqr" @close="showqr=false"/>


    <div class="box">
      <header class="header-me">
        <h2>Account Overview</h2>
      </header>

      <div class="password">
        <h3>Password</h3>
        <span class="spn">Last Modified </span>
        <span class="spn">4/5/21</span>
        <span class="spn">05:12</span>
        <orangbtn class="btnme" value="Change" @click="showpass = true"/>
      </div>

      <div class="Authenticator">
        <div class="item-1"><span class="tow-fa">2FA</span></div>
        <div class="item-2"><a href="#" @click="showemail = true">Email</a></div>
        <div class="item-3"><a href="#" @click="showsms =!showsms">SMS</a></div>
        <div class="item-4"><a href="#" @click="showqr =!showqr">Google Authenticator</a></div>
      </div>

      <div class="LoginManagement">
        <a href="#" class="login-span">Login Management</a>
      </div>
    </div>
  </div>
</template>

<script>
import Orangbtn from "../Tools/orangbtn";
import ChangePassword from "./Modal/ChangePassword";
import EmailModalpass from "./Modal/EmailModalpass";
import SmsModal from "./Modal/SmsModal";
import GoogleModal from "./Modal/GoogleModal";
export default {
  name: "SecuritySettings",
  components: {GoogleModal, Orangbtn, ChangePassword,EmailModalpass,SmsModal},
  data() {
    return {
      showpass: false,
      showemail:false,
      showsms: false,
      showqr:false
    }
  }
}
</script>

<style scoped>
ul {
  display: inline-block;
  color: white;
  margin-top: 30px;
}

li {
  list-style: none;
}

ul li a {
  text-decoration: none;
  color: white;
}

.security {
  flex-grow: 1;
}

.spn {
  font-size: 12px;
  color: #AFAFAF;
  margin-left: 5px;
}

.header-me {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  color: white;
  margin-top: 20px;
}

.password {
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.box {
  width: 100%;
}

.Authenticator {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 30px;
  color: white;
  align-items: center;
}

.tow-fa {
  font-size: 16px;
  font-weight: bold;
}

a {
  color: #AFAFAF;
  text-decoration: none;
  font-size: 12px;
  position: relative;
}

a:focus {
  color: white;
  font-size: 14px;
}

a:hover {
  cursor: pointer;
}

a:focus::after {
  content: "";
  position: absolute;
  width: 55%;
  background-color: #26FFFF;
  height: 3px;
  bottom: 0;
  top: 20px;
  left: 0;
  border-radius: 20px;
}

.LoginManagement {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.login-span {
  color: #FF5107 !important;
  font-size: 14px;
  text-decoration: none;
}

@media only screen and (max-width: 428px) {
  .btnme {
    margin-top: 20px;
  }
}

</style>