<template>
  <div>
    <app-slid></app-slid>
    <app-trade></app-trade>
    <appchoise></appchoise>
    <app-galery></app-galery>
    <app-ready></app-ready>
  </div>
</template>

<script>
import Slid from "./slid"
import Trade from "./trade"
import Choise from "./choise"
import Galery from "./galery"
import Ready from "./readystart"

export default {
  name: "home",

  "components": {
    "appSlid": Slid,
    "appTrade": Trade,
    "appchoise": Choise,
    "appReady": Ready,
    "appGalery": Galery
  }
}
</script>

<style scoped>

</style>