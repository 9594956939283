<template>
  <div class="main-trade">
    <div class="padding-main">
      <div class="first-row">
        <div class="market-info">
          <MarketInfo/>
        </div>
        <div class="chart">
          <VueTradingView :options="{
          theme : 'dark',
          height : '410',
          width : 'auto',
        }"/>
        </div>
        <div class="buy-sell">
          <BuyAndCell/>
        </div>
      </div>
      <div class="second-row">
        <div class="orderBook">
          <order-history/>
        </div>
        <div class="history">
          <order-book/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketInfo from "./MarketInfo";
import OrderHistory from "../Trade/Order-history";
import OrderBook from "./Order-Book";
import BuyAndCell from "./BuyAndCell";
import VueTradingView from 'vue-trading-view';

export default {
  name: "Trade",
  components: {BuyAndCell, OrderBook, OrderHistory, MarketInfo, VueTradingView},
}
</script>

<style lang="scss" scoped>
$border: none;
$background: rgba(49, 55, 84, 0.6);
$radius: 20px;

.basic-box {
  background: rgba(49, 55, 84, 0.6);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  border: $border;
}

.main-trade {
  display: flex;
  flex-direction: column;
  background-color: #0E122B;
  border: $border;
  color: white;
  gap: 10px;

  .padding-main {
    width: 98%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .first-row {
    display: flex;
    flex-direction: row;
    border: $border;
    gap: 10px;
    flex-wrap: wrap;

    .market-info {
      flex-grow: 1;
      flex-basis: 100px;
      padding: 0;
      background: rgba(25, 34, 73, 0.56);
      @extend .basic-box
    }

    .buy-sell {
      flex-grow: 1;
      flex-basis: 200px;
      @extend .basic-box
    }

    .chart {
      flex-grow: 3;
      flex-basis: 300px;
      padding: 0;
      overflow: hidden;
      border-radius: 15px;
    }
  }

  .second-row {
    display: flex;
    flex-direction: row;
    border: $border;
    gap: 10px;
    flex-wrap: wrap;

    .orderBook {
      flex-grow: 3;
      flex-basis: 300px;
      @extend .basic-box
    }

    .history {
      flex-grow: 2;
      flex-basis: 200px;
      @extend .basic-box;
      padding: 0;
    }
  }
}
</style>