<template>
  <div class="buy-and-cell" :class="{'selected-green':trade , 'selected-red':!trade}">
    <div class="header-buy-and-cell">
      <button @click.prevent="trade = !trade" :class="{'green-btn':trade , 'red-btn':!trade}">
        <label v-if="trade">Buy</label>
        <label v-if="!trade">Sell</label>
      </button>
      <div class="links">
        <a :class="{'selected' : select === 'Limit'}" @click="select = 'Limit'">Limit </a>
        <a :class="{'selected' : select === 'Market'}" @click="select = 'Market'">Market </a>
      </div>
    </div>

    <div class="main">
      <div class="inputs">
        <input placeholder="USDT" class="input-buy-cell" type="text">
        <span class="value-1">Price</span>
      </div>

      <div class="inputs">
        <span class="fee">Minimum 0.003 BTC </span>
        <input placeholder="USDT" class="input-buy-cell" type="text">
        <input type="range" min="0" max="100" hidden>
        <span class="value-1">Amount</span>
      </div>

      <div class="inputs">
        <span class="fee">Fee 0.01 </span>
        <input placeholder="USDT" class="input-buy-cell" type="text">
        <span class="value-1">Total</span>
      </div>

      <div class="expo">
        <label class="check-box-label" :class="{'checked' : check}">
          <input type="checkbox" v-model="check" hidden>
          <span class="check-box-icon"/>
          Expiry Date
        </label>

        <div class="img-expo">
        <img src="../../assets/Login/calender.png" alt="">
        </div>
      </div>

      <div class="button-div">
        <button class="btn btn-block" :class="{'green-large-btn':trade , 'red-large-btn':!trade}">
          <label v-if="trade">Buy Bitcoin</label>
          <label v-if="!trade">Sell Bitcoin</label>
        </button>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "BuyAndCell",
  data() {
    return {
      select: 'Market',
      trade: true,
      check: false
    }
  }
}
</script>

<style lang="scss" scoped>
$green: #06F148;
$red: #E21C7B;
.buy-and-cell {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  gap: 30px;

  .header-buy-and-cell {
    display: flex;
    justify-content: space-between;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .inputs {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      justify-content: center;

      input[type = 'range']{
        -webkit-appearance: none;
        background: linear-gradient(90deg, #7BB5FA 0%, #0ACD87 59.42%, #06F148 104.3%);
      }

      input[type = range]::-webkit-slider-thumb{
        -webkit-appearance: none;
        width: 10px;
        height: 10px;
      }

      input {
        border: none;
        background: #1B1F36;
        color: #CDB5B5;
        font-size: 16px;
        padding: 5px 5px 5px 10px;
        border-radius: 8px;
        box-shadow: 2px 4px 8px 0 #171A27 inset;
        position: relative;
        width: 100%;
      }

      .value-1 {
        position: absolute;
        right: 50%;
        top: 10%;
        color: #CDB5B5;
        font-size: medium;
      }

      .fee{
        font-size: small;
        position: absolute;
        bottom: 100%;
        right: 0;
        color: #CDB5B5;
      }

    }

   .expo {
     display: flex;
     flex-grow: 1;
     justify-content: space-between;
     width: 100%;
     align-items: center;

      .check-box-label {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        font-size: 13px;
        color: #CDB5B5;

        .check-box-icon {
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #B4B4B4;
          background-color: transparent;
        }

        &.checked {
          .check-box-icon {
            background-color: #fff;
          }
        }

        input[type="checkbox"]:checked {
          border: #040F26;

          + {
            .check-box-icon {
              background-color: #fff;
            }
          }
        }

      }
    }

    .button-div{
      display: flex;
      width: 100%;
    }
  }

  button {
    border: 0;
    background-color: transparent;
    border-radius: 5px;
    font-size: medium;
    width: 100%;
    height: 100%;
    padding: 5px 0 5px 5px;
    text-align: left;
    cursor: pointer;

    &.green-btn {
      background-color: $green;
      width: 30%;
      background-image: url("../../assets/trade/Arrow-right.png");
      background-position: 90% 50%;
      background-repeat: no-repeat;
    }

    &.red-btn {
      background-color: $red;
      width: 30%;
      background-image: url("../../assets/trade/Arrow-right.png");
      background-position: 90% 50%;
      background-repeat: no-repeat;
    }

    &.green-large-btn{
      background-color: $green;
      text-align: center;
    }

    &.red-large-btn{
      background-color: $red;
      text-align: center;
    }
  }

  a {
    font-size: small;
    margin: 0 30px 0 0;
    text-decoration: none;
    color: white;
    opacity: 0.7;
    position: relative;
    cursor: pointer;


    &.selected {
      font-size: medium;
      color: white;
      opacity: 1;

      &::after {
        content: "";
        position: absolute;
        width: 50%;
        background-color: white;
        height: 3px;
        bottom: 0;
        top: 25px;
        left: 0;
        border-radius: 20px;
      }
    }
  }

  &.selected-green {
    a::after {
      background-color: $green;
    }
  }

  &.selected-red {
    a::after {
      background-color: $red;
    }
  }
}

</style>