<template>
  <div class="email-input-div">
    <input   :type="type"  :placeholder="placeholder" class="email-input form-control" @keypress="VisiblePassword">
    <img v-if="icon" :src="require('../../assets/Login/'+icon)" alt="" class="email-tag" @click="changeType">
  </div>
</template>

<script>

const a = {
  email : 'notfication.png',
  password : 'privecy.png',
  passwordVisible : 'eaye.png',
  calender : 'calender.png',

}

export default {
  name: "TheInput",
  props:['type','placeholder','logo'],
  data(){
    return{

    }
  },
  computed:{
    icon(){
      return a[this.logo]
    }
  },

  methods:{
    VisiblePassword(){
     if (this.logo === 'password'){
        this.logo = 'passwordVisible';
     }
    },

    changeType(){
      if (this.type === 'password'){
        this.type = 'text'
      }

      else if (this.type === 'text'){
        this.type = 'password'
      }
    }
  }

}
</script>

<style scoped>
.email-input-div {
  display: flex;
  margin-bottom: 30px;
}


.email-input {
  border: none;
  background: #1B1F36;
  color: #CDB5B5;
  font-size: 16px;
  padding: 5px 5px 5px 40px;
  border-radius: 8px;
  box-shadow: 2px 4px 8px 0 #171A27 inset;
}


.email-tag {
  height: 20px;
  position: absolute;
  margin: 7px 10px;
  cursor: pointer;
}

.padding{
  padding-left: 20px;
}
</style>