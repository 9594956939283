<template>
  <div class="credit-cart">
    <div class="main">

      <div class="cart-info">
        <div class="header-info-cart">Card Info</div>
        <div class="input-cart-info">
         <TheInput placeholder="Credit/Debit Card Number" />
        </div>
        <div class="inputs-cart-info">
          <div class="item-input-1">
          <TheInput placeholder="Cardholder Name"/>
          </div>
          <div class="item-input-2">
            <TheInput placeholder="Expiry Date" logo="calender"/>
          </div>
        </div>
        <div class="header-card-verification">
          Credit Card Verification
        </div>
        <div class="insert-cart">
          <Theinsertimage title="Front of Credit Card" alert="*Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i"/>
        </div>
        <div class="submit-btn">
          <TheButtonForm value="Submit"/>
        </div>
      </div>

      <div class="show-carts">
        <TheCreditCart/>
        <TheCreditCart/>
        <TheCreditCart/>
        <TheCreditCart/>
      </div>
    </div>
  </div>
</template>

<script>
import TheInput from "../Tools/TheInput";
import Theinsertimage from "../Tools/Theinsertimage";
import TheButtonForm from "../Tools/TheButtonForm";
import TheCreditCart from "../Tools/TheCreditCart";
export default {
  name: "CreaditCart",
  components: {TheCreditCart, TheButtonForm, Theinsertimage, TheInput},
}
</script>

<style scoped>
.credit-cart {
  background-color: #02163E;
  display: flex;
}

.main {
  width: 80%;
  justify-content: center;
  display: flex;
  margin: 20px auto;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
}

.cart-info {
  display: flex;
  flex-grow: 2;
  flex-basis: 300px;
  border-radius: 10px;
  padding: 20px;
  background-color: #1E2A4B;
  flex-direction: column;
  gap: 20px;
}

.show-carts {
  display: flex;
  flex-grow: 1;
  flex-basis: 200px;
  border-radius: 10px;
  padding: 20px;
  background-color: #1E2A4B;
  flex-direction: column;
  max-height: 92vh;
  overflow: auto;
}

.header-info-cart {
  justify-content: flex-start;
  color: #CDB5B5;
  font-size: 14px;
}

.inputs-cart-info{
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.item-input-1{
  flex-grow: 1;
  flex-basis: 100px;
}

.item-input-2{
  flex-grow: 1;
  flex-basis: 100px;
}

.header-card-verification{
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: #CDB5B5;
}

.show-carts{
  display: flex;
  flex-direction: column;
}
</style>