<template>
  <div class="faq">
    <TheHeaderTerms title="Help"/>
    <body-terms :text="text"/>
    <div class="blue-bottom-div">
      <div class="header">
        FAQ
      </div>
      <div class="cols">
        <div class="col1">
          <div class="ol-list">

            <div v-for="(i , index) in title" :key="index">
              <a class="number-list">{{ index + 1 }}</a>
              <a class="text-list">{{ i }}</a>
            </div>

          </div>
        </div>

        <div class="col2">
          <p v-for="t in textTitle" :key="t">{{ t }}</p>
        </div>
      </div>
    </div>

    <div class="btnSupport">
      <button class="suport-btn btn">SUPPORT <img src="../../assets/Faq/headphones.png" alt=""></button>
    </div>

  </div>
</template>

<script>
import TheHeaderTerms from "../Tools/TheHeaderTerms";
import BodyTerms from "../Terms/BodyTerms";

export default {
  name: "FAQ",
  components: {BodyTerms, TheHeaderTerms},
  data() {
    return {
      title: ['How to sign up at Warex?', 'How to sign up at Warex?', 'How to sign up at Warex?', 'How to sign up at Warex?',],

      textTitle: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua1',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua2',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua3',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua4',
      ],

      FaqText: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',

      ],
      text: [
        {
          title: 'Accepting the Terms1',
          text: 'Lorem ipsum dolor1 sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi vitae suscipit tellus mauris a diam maecenas sed. Placerat duis ultricies lacus sed turpis tincidunt id aliquet.'
        },
        {
          title: 'Accepting the Terms2',
          text: 'Lorem ipsum dolor1 sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et netus. Mattis pellentesque id nibh tortor id aliquet lectus proin. Sapien faucibus et molestie ac feugiat sed lectus vestibulum. Ullamcorper velit sed ullamcorper morbi tincidunt ornare massa eget. Dictum varius duis at consectetur lorem. Nisi vitae suscipit tellus mauris a diam maecenas sed enim. Velit ut tortor pretium viverra suspendisse potenti nullam. Et molestie ac feugiat sed lectus. Non nisi est sit amet facilisis magna. Dignissim diam quis enim lobortis scelerisque fermentum. Odio ut enim blandit volutpat maecenas volutpat. Ornare lectus sit amet est placerat in egestas erat. Nisi vitae suscipit tellus mauris a diam maecenas sed. Placerat duis ultricies lacus sed turpis tincidunt id aliquet.'
        },
      ],
    }
  },

  methods: {
    show() {

    }
  }
}
</script>

<style scoped>
.btnSupport{
  display: flex;
  justify-content: end;
}
.suport-btn {
  border: 0;
  padding: 15px 35px;
  background: linear-gradient(98.51deg, #1CBFE2 7.26%, #0F74EB 118.38%);
  color: white;
  border-radius: 54px;
  font-size: 1.5rem;
  position: relative;
  top: 170px;
}

.blue-bottom-div {
  width: 80%;
  align-self: flex-start;
  background-color: #040F26;
  border-radius: 0 50px 50px 0;
  height: auto;
  display: flex;
  position: relative;
  bottom: 0;
  top: 160px;
  padding: 50px;
  color: white;
  flex-direction: column;
}

.faq {
  background: #FCFCFC;
  box-shadow: 5px 4px 15px rgba(2, 22, 62, 0.38);
  border-radius: 0 0 100px 0;
  width: 90%;
  margin-bottom: 200px;
}

.cols {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: flex-start;
}

.col1 {
  flex-grow: 1;
  line-height: 3;
}

.col2 {
  flex-grow: 1;
  border: 1px solid white;
  border-radius: 10px;
  width: 30%;
  font-size: 14px;
  padding: 25px;
}

.header {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  padding: 20px 0 40px 42px;
  font-size: 25px;
}

.ol-list :hover {
  color: #FF8B04;
}

.number-list {
  font-size: 16px;
  color: white;
  margin-right: 5px;
  text-decoration: none;
  cursor: pointer;
}

.text-list {
  font-size: 14px;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
</style>