<template>
  <div class="buttons">
    <router-link to="/auth/login" class="login">Login</router-link>
    <button class="btnme"><router-link to="/auth/singup" class="link-btn">Register</router-link></button>
  </div>
</template>

<script>
export default {
  name: "NotRegisterNav"
}
</script>

<style scoped>
.login {
  line-height: 2.5;
  color: #fcfcfc;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.login:hover {
  color: #ff5107;
  text-decoration: none;
}

.btnme {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #ff5107;
  color: #ff5107 !important;
  flex-basis: 117px;
  height: 36px;
  text-decoration: none;

}

.link-btn{
  text-decoration: none;
  color: #FF5107;
  font-size: 16px;
}

.btnme:hover {
  background-color: #ff4c0091;
  color: white;
}

.buttons {
  border: 0;
  height: 46px;
  background: rgba(30, 42, 75, 0.6);
  border-radius: 10px;
  flex-grow: 3;
  justify-content: space-around;
  display: flex;
  align-items: center;
  order: 1;
}

</style>