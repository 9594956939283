<template>
  <div class="bg">
    <div class="about-main">
      <div class="item1">
        <h3>About</h3>
        <h1>WAREX</h1>
      </div>
      <div class="item2">
        <div class="sec1">
          <img src="../../assets/About/data.png" alt="">
          <h4>Our Service</h4>
        </div>
        <p>
          {{ServiceText}}
        </p>
      </div>
      <div class="item3">
        <div class="sec1">
          <img src="../../assets/About/users.png" alt="">
          <h4>Our Team </h4>
        </div>
        <p>
          {{TeamText}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {
      ServiceText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
          '          magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel\n' +
          '          elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et\n' +
          '          netus.',

      TeamText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore\n' +
          '          magna aliqua. Egestas purus viverra accumsan in nisl nisi. Arcu cursus vitae congue mauris rhoncus aenean vel\n' +
          '          elit scelerisque. In egestas erat imperdiet sed euismod nisi porta lorem mollis. Morbi tristique senectus et\n' +
          '          netus.'
    }
  }
}
</script>

<style scoped>
.sec1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.sec1 img {
  margin: 0 10px 10px 0;
}

.bg {
  background: #02163E;
}

.about-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #02163E;
  justify-content: center;
  align-items: baseline;
  margin: 0 auto;
  padding: 20px 0;
  width: 80%;
}

.item1 {
  flex-grow: 2;
  color: white;
  flex-basis: 100px;
  margin-bottom: 30px;
}

.item1 h1 {
  font-size: 33px;
}

.item2 {
  flex-grow: 1;
  color: white;
  flex-basis: 100px;
  margin-right: 100px;
}

.item2 p {
  font-size: 14px
}

.item3 {
  flex-grow: 1;
  color: white;
  flex-basis: 100px;
}

.item3 p {
  font-size: 14px
}

@media screen and (max-width: 992px) {
  .about-main{
    flex-direction: column;
  }
}
</style>