<template>
  <div class="box-dashbord main-invited">
    <div class="main">
      <div class="header">
        <h2>Invite Friends</h2>
        <p>Invite your Friends to Warex and earn up to 30% of their transaction fees</p>
      </div>

      <div class="main-input">
        <div class="input-code">
          <div class="invite-code">
            <p>invite code</p>
            <input type="text" class="input-me1" :value="inviteCode">
          </div>

          <div class="link-code">
            <p>Invite Link</p>
            <div class="copy">
            <input  type="text" class="input-me2" :value="inviteLink">
            <button class="copy-btn">Copy</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "invite",
  data(){
    return{
      inviteCode:145258,
      inviteLink:'https://www.warex.com/join/8104722'
    }
  }
}
</script>

<style scoped>
.main-invited{
 flex-grow: 2;
}

.header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: white;
}

.header p {
  font-size: 14px;
}

.input-code {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.link-code {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 14px;
}

.invite-code {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 14px;
}

.main {
  display: flex;
  flex-direction: column;
}

.input-me1{
  background: #404459;
  box-shadow: inset 2px 4px 8px #171A27;
  border-radius: 8px;
  border: 0;
  padding: 5px;
  max-width: 60%;
}

.input-me2{
  background: #404459;
  box-shadow: inset 2px 4px 8px #171A27;
  border-radius: 8px;
  border: 0;
  padding: 5px;
}

.main-input{
  margin-top: 30px;
}

.copy-btn{
  background: linear-gradient(98.51deg, #1CBFE2 7.26%, #0F74EB 118.38%);
  border-radius: 7px;
  border: 0;
  padding: 5px 20px;
}

.copy{
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 600px) {
  .input-code{
    flex-direction: column;
  }
}
</style>