<template>
  <button class="btn-oranged" @click="$emit('click')">
    <router-link to="" class="link-btn">{{value}}</router-link>
  </button>
</template>

<script>
export default {
  props:['value'],
  name: "orangbtn"
}
</script>

<style scoped>
.btn-oranged {
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #ff5107;
  color: #ff5107 !important;
  flex-basis: 117px;
  padding: 5px 20px;
  text-decoration: none;
  font-size: 14px;
}

.btn-oranged a {
  color: #ff5107 !important;
  text-decoration: none;
}
</style>