<template>
<form>
  <header-form header1="Password Reset" selected="header1"/>
  <send-code/>
  <the-input logo="password" type="password" placeholder="New password"/>
  <IAmNotRobot/>
  <TheButtonForm value="Set New Password"/>
</form>
</template>

<script>

import HeaderForm from "../../Tools/HeaderForm";
import TheInput from "../../Tools/TheInput";
import IAmNotRobot from "../../Tools/IAmNotRobot";
import TheButtonForm from "../../Tools/TheButtonForm";
import SendCode from "../RestPassword/Sendcode"

export default {
  name: "ResetPassword",
  components:{TheButtonForm, IAmNotRobot, TheInput, HeaderForm , SendCode}
}
</script>

<style scoped>

</style>