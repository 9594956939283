<template>
  <div>
    <app-about-us></app-about-us>
    <app-contact-us></app-contact-us>
    <app-sochialmedia></app-sochialmedia>
  </div>
</template>

<script>
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Sochialmedia from "./Sochialmedia";
export default {
  name: "About",
  "components":{
    "appAboutUs" : AboutUs,
    "appContactUs":ContactUs,
    "appSochialmedia" : Sochialmedia
  }
}
</script>

<style scoped>

</style>