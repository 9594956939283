<template>
  <button class="btn login-btn" @click="$emit('click')">
    {{value}}
  </button>
</template>

<script>
export default {
  props:['value'],
  name: "TheButtonForm"
}
</script>

<style scoped>
.btn{
  display: flex;
  flex-direction:column;
  width: 100%;
}

.login-btn{
  padding: 10px;
  color: white;
  background: linear-gradient(98.51deg, #1CBFE2 7.26%, #0F74EB 118.38%);
  font-size: 14px;
  border-radius: 3px;
}

</style>