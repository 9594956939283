<template>
  <div class="container-flex footer">
    <div class="logo-footer">
      <a href="#"><img src="../../../assets/footer/logofooter.png"/></a>
      <small class="commu">Community</small>
      <div class="sochial">
        <a href="#">
          <img src="../../../assets/footer/inestageram.png" alt="inestageram"/>
        </a>
        <a href="#">
          <img src="../../../assets/footer/telegeram.png" alt="telegeram"/>
        </a>
        <a href="#"
        ><img src="../../../assets/footer/twitter.png" alt="twitter"
        /></a>
        <a href="#"
        ><img src="../../../assets/footer/linkdin.png" alt="linkdin"
        /></a>
      </div>
    </div>
    <div class="menu-footer">
      <div class="nav-1">
        <ul>
          <li class="lime"> About Us</li>
          <li v-for="n in nav1" :key="n[0]"><a :href="n.path">{{ n.name }}</a></li>
        </ul>
      </div>
      <div class="nav-2">
        <ul>
          <li class="lime"> Learn</li>
          <li v-for="n in nav2" :key="n[1]"><a :href="n.path">{{ n.name }}</a></li>
        </ul>
      </div>
      <div class="nav-3">
        <ul>
          <li class="lime"> Support</li>
          <li v-for="n in nav3" :key="n[2]"><a :href="n.path" @click.prevent="$router.push(n.path)">{{ n.name }}</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Thefooter",
  data() {
    return {

      nav1: [
        {path: 'AboutUs', name: 'AboutUs'},
        {path: 'Terms', name: 'Terms and Conditions'},
      ],
      nav2: [
        {path: 'Blog', name: 'Blog'},
        {path: 'Trading', name: 'Trading Guide'},
        {path: 'Help', name: 'Help'},
        {path: 'FAQ', name: 'FAQ'},
      ],
      nav3: [
        {path: 'Blog', name: 'Blog'},
        {path: 'Support', name: 'Support Center'},
        {path: 'Help', name: 'Help'},
        {path: 'FAQ', name: 'FAQ'}
      ],
    }
  }
}
</script>

<style scoped>
* {
  font-size: 1.5rem;
}

.menu-footer ul li a {
  color: #fcfcfc;
  text-decoration: none;
}

.menu-footer ul li {
  list-style: none;
  margin-bottom: 30px;
}

.lime {
  font-size: 24px;
  margin-bottom: 46px;
  display: block;
  color: white;
}


.commu {
  position: relative;
  bottom: 30px;
  padding-left: 20px;
  font-size: 16px;
  color: white;
}

.sochial {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
}

.sochial a {
  margin-right: 50px;
}

.container-flex {
  display: flex;
  flex-direction: row;
  background-color: #040F26;
  flex-wrap: wrap;
}

.logo-footer {
  display: flex;
  flex-grow: 4;
  flex-direction: column;
}

.menu-footer {
  display: flex;
  flex-grow: 6;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: first baseline;
  margin-top: 50px;
}

.nav-1 {
  flex-grow: 2;
}

.nav-2 {
  flex-grow: 1;
}

.nav-3 {
  flex-grow: 1;
}

@media screen and (max-width: 879px) {
  .menu-footer {
    flex-direction: column;
  }

  .logo-footer {
    align-items: center;
  }

  .menu-footer {
    align-items: center;
  }

  .nav-1 {
    flex-grow: 0;
  }

  .nav-1 ul {
    padding-left: 40px;
  }

  .nav-2 ul {
    padding-left: 0;
  }

  .nav-3 ul {
    padding-left: 0;
  }


}

</style>