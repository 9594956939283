<template>
  <the-modal @close="$emit('close')">
    <div>
      <div class="header">
        <span> Bind Google Authenticator</span>
      </div>

      <div class="qrcode">
        <img src="../../../assets/Dashbord/qr_code.png" alt="">
      </div>

      <div class="codinput">
        <TheCodeInput />
      </div>

    </div>
  </the-modal>
</template>

<script>
import TheModal from "../../Tools/TheModal";
import TheCodeInput from "../../Tools/TheCodeInput";

export default {
  name: "GoogleModal",
  components: {TheModal , TheCodeInput},
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.header span {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.codinput{
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

.qrcode{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.qrcode img{
  width: 50%;
  background-color: #C4C4C4;
  border-radius: 10px;
}
</style>