<template>
  <div class="personal-info">
    <div class="padding">
      <div class="header">
        <p class="title">Personal Info</p>
      </div>

      <div class="form-select">
        <select class="select-custom form-select">
          <option v-for="opt in options" :key="opt">{{ opt }}</option>
        </select>
      </div>

      <div class="form-input-text">
        <TheSuccessinput type="text" placeholder="Name"/>
        <TheSuccessinput type="text" placeholder="Family"/>
      </div>

      <div class="header">
        <p class="title">ID Verification</p>
      </div>

      <div class="typeId">
        <div class="left-typeId">
          <p class="title-type">Type of ID</p>
          <div class="text-type">
            <p class="pass-txt">Passport</p>
            <p class="idcart-txt">Government-issued Card</p>
            <TheSuccessinput type="text" placeholder="ID"/>
          </div>
        </div>
      </div>
      <div class="insert-pic">
        <Theinsertimage title="Front of ID Document" alert='
     *Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i
     '/>
      </div>

      <div class="text-caption">
        <span class="txt-one">Statement Verification</span>
        <span class="txt-one">
        Upload a photo of you handholding your ID and handwritten statement:
        “ٌَWAREX WAREX WAREX”
      </span>
      </div>

      <div class="insert-pic">
        <Theinsertimage title="Front of ID Document"/>
      </div>

      <label class="check-box-label" :class="{'checked' : check}">
        <input type="checkbox" v-model="check" hidden>
        <span class="check-box-icon"/>
        I’m the legitimate owner of the above ID
      </label>

      <TheButtonForm value="Submit" @click="submit"/>
      <div>

      </div>
    </div>
  </div>
</template>

<script>
import Theinsertimage from "../Tools/Theinsertimage";
import TheButtonForm from "../Tools/TheButtonForm";
import TheSuccessinput from "../Tools/TheSuccessinput";

export default {
  name: "VerificatIonEdite",
  components: {TheButtonForm, Theinsertimage, TheSuccessinput},
  data() {
    return {
      check: false,
      options: ['Iran', 'Iraq', 'Germany'],
    }
  },

  methods: {
    submit() {
      this.state.userInfo.personal = true
      this.$router.push({name: 'Verify'})
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-info {
  display: flex;
  flex-direction: column;
  background: #1E2A4B;
  border-radius: 29px;

}

.padding {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 20px auto;
  gap: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.title {
  font-size: 14px;
  color: #CDB5B5;
}

.form-select {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.select-custom {
  width: 95%;
  background: #1B1F36;
  box-shadow: inset 2px 4px 8px #171A27;
  border-radius: 8px;
  border: 0;
  height: 35px;
  font-size: 14px;
  color: #CDB5B5;
  padding-left: 11%;
}

.form-input-text {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.typeId {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.left-typeId {
  display: flex;
  flex-direction: column;
}

.text-type {
  display: flex;
  gap: 44px;
  align-items: baseline;
}

.title-type {
  font-size: 14px;
  color: #CDB5B5;
}

.pass-txt {
  font-size: 12px;
  position: relative;
}

.pass-txt:after {
  content: "";
  position: absolute;
  width: 55%;
  background-color: #26FFFF;
  height: 3px;
  bottom: 0;
  top: 20px;
  left: 0;
  border-radius: 20px;
}

.idcart-txt {
  font-size: 12px;
  color: #CDB5B5;
}

.text-caption {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.txt-one {
  font-size: 14px;
  color: #CDB5B5;
}

.check-box-label {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: #CDB5B5;

  .check-box-icon {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #B4B4B4;
    background-color: transparent;
  }

  &.checked {
    .check-box-icon {
      background-color: #fff;
    }
  }

  input[type="checkbox"]:checked {
    border: #040F26;

    + {
      .check-box-icon {
        background-color: #fff;
      }
    }
  }

}

</style>