<template>
  <div class="icons">
    <div class="buttons">
      <span class="name-user">Hadi Moradi</span>
      <img src="../../../assets/Header/user.png" alt="">
    </div>
    <img src="../../../assets/Header/bag.png" alt="">
  </div>
</template>

<script>
export default {
  name: "MobileNavbarnotregister"
}
</script>

<style scoped>
.name-user{
  font-size: 16px;
  color:#CDB5B5;
;
}
.buttons {
  border: 0;
  height: 46px;
  background: rgba(30, 42, 75, 0.6);
  border-radius: 10px;
  flex-grow: 3;
  justify-content: space-around;
  display: flex;
  align-items: center;
  order: 1;
  margin: 10px;
}

.icons {
  display: flex;
  flex-grow: 2;
  align-items: center;
  justify-content: flex-end;

}
</style>