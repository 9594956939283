import { render, staticRenderFns } from "./BuyAndCell.vue?vue&type=template&id=58858694&scoped=true&"
import script from "./BuyAndCell.vue?vue&type=script&lang=js&"
export * from "./BuyAndCell.vue?vue&type=script&lang=js&"
import style0 from "./BuyAndCell.vue?vue&type=style&index=0&id=58858694&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58858694",
  null
  
)

export default component.exports