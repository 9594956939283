<template>
  <div>
    <div class="flex-container">
      <div class="logo">
        <router-link to="/"><img src="../../../assets/Header/logoheader.png" alt="logoheader"/></router-link>
      </div>

     <app-mobile-registr-nav v-if="registr"></app-mobile-registr-nav>
      <app-mobile-navbarnotregister v-if="!registr"></app-mobile-navbarnotregister>
      <a @click="show =! show"><i class="fa fa-bars custom-bars"></i></a>
    </div>
    <div class="mobile-flex-menu" v-if="show">
      <a href="#" class="link-mob" v-for="l in links" :key="l">{{l}}</a>
    </div>
  </div>
</template>

<script>
import MobileRegistrNav from "./MobileRegistrNav";
import MobileNavbarnotregister from "./MobileNavbarnotregister"
export default {
  name: "Mobilenavbar",
  data() {
    return {
      show: false,
      registr : true,
      links: ["Buy/Sell", "Fees", "Learn", "Help"]
    }
  },

  components:{
    appMobileRegistrNav : MobileRegistrNav,
    appMobileNavbarnotregister :MobileNavbarnotregister
  }
}
</script>

<style scoped>

.mobile-flex-menu {
  display: flex;
  flex-direction: column;
  background-color: #040F26;
  align-items: center;
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #040F26;
  opacity: 1;
  z-index: 10;
}

.custom-bars {
  font-size: 39px;
  color: white;
  order: 2;
}

.logo {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.link-mob {
  display: block;
  text-decoration: none;
  color: white;
  font-size: 16px;
  background-color: transparent;
  padding: 10px;
  flex-basis: 100%;
}

.link-mob:hover {
  background-color: #252a3b;
}
</style>