<template>
  <div class="Transactions">
    <div class="img"><img :src="require('../../assets/Card/'+icon)" alt=""></div>
    <div class="number">
      <div class="amount">{{item.amount}}</div>
      <div class="number-cart">{{item.numberCart}}</div>
    </div>
    <div class="date">{{item.time}}</div>
  </div>
</template>

<script>
const a = {
  yellow: 'withdraw-yellow.png',
  red: 'withdraw-red.png',
}
export default {
  props: ['item'],
  name: "TheTransactions",
  computed: {
    icon() {
      return a[this.item.logo]
    }
  },
}
</script>

<style scoped>
.Transactions {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-top: 30px;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  gap: 10px;
}

.number{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  flex-grow: 1;
}

.img{
  display: flex;

}

.date{
  display: flex;
  font-size: 12px;
}
</style>