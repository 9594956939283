<template>
  <div class="orderhistory box-dashbord">
    <div class="header">
      <div class="item1"><a :class="{'selected' : select === 'Orders'}" @click="select = 'Orders'">Open Orders</a></div>
      <div class="item2"><a :class="{'selected' : select === 'History'}" @click="select = 'History'">Order History</a>
      </div>
      <div v-if="history" class="item3"><a class="history" href="">History Report</a></div>
    </div>
    <div class="table">
      <table class="table table-borderless">
        <tr>
          <th>Pair</th>
          <th>Type</th>
          <th v-if="width > 768">Time/Expiration</th>
          <th v-if="width > 768">Amount</th>
          <th>Price</th>
          <th>Total</th>
          <th v-if="width > 768">Status</th>
        </tr>

        <tr v-for="d in data" :key="d[0]">
          <td>{{ d.Pair }}</td>
          <td v-if="width > 768">{{ d.Type }}</td>
          <td v-if="width > 768">{{ d.Expiration }}</td>
          <td>{{ d.Amount }}</td>
          <td>{{ d.Price }}</td>
          <td>{{ d.Total }}</td>
          <td v-if="width > 768">
            <img src="../../assets/trade/chart-70.png" alt="">
          </td>
        </tr>

      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderHistory",
  props:['history'],
  data() {
    return {
      width: 0,
      select: 'Orders',
      data: [
        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: ''
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: ''
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: ''
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: ''
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: ''
        },
      ],
      options: {
        colors: ['#FF5107'],
        dataLabels: {
          enabled: false,
        },

        chart: {
          offsetX: 10,
          offsetY:10
        },


      },
      series: [100],

    }
  },

  "mounted"() {
    this.width = screen.width
  },

}
</script>

<style lang="scss" scoped>
.orderhistory {
  flex-basis: 200px;
  flex-grow: 2;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}


.item2 {
  flex-grow: 3;
  margin-left: 20px;
}

.item3 {
  flex-grow: 1;
}


a {
  font-size: small;
  margin: 30px 30px 0 0;
  text-decoration: none;
  color: white !important;
  position: relative;
  cursor: pointer;

  &.selected {
    font-size: medium;

    &:after {
      content: "";
      position: absolute;
      width: 50%;
      background-color: #26FFFF;
      height: 3px;
      bottom: 0;
      top: 25px;
      left: 0;
      border-radius: 20px;

    }
  }
}

.history {
  color: #FF5107;
  font-size: 12px;
  text-decoration: none;
  position: relative;
}

table {
  background-color: transparent !important;
  color: white;
  font-size: 12px;
  margin-top: 20px;
}

table th {
  border: none;
  color: #EDEDED !important;
  font-weight: 100;
  padding: 0;
}

.table td {
  border: none;
}


.table th, .table td {
  vertical-align: middle;
  padding: 5px !important;
}
</style>

<style>

</style>