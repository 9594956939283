<template>
  <div class="container-fluied pos">
    <div
        class="treader table-responsive col-lg-10 col-md-10 col-sm-10 col-xs-10"
    >
      <table class="table table-borderless">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Last Price</th>
          <th v-if="width > 768" scope="col">24h Change Chart</th>
          <th scope="col" style="text-align: center">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="t in trade" :key="t[0]">
          <td class="col-md-3">
            <div class="row trad-pic">
              <div
                  class="
                    col-lg-3 col-md-3 col-sm-3
                    col-xs-3
                    pic-trad
                  "
              >
                <img :src="require('../../assets/trade/'+t.Logo)" :alt="t.name"/>
              </div>
              <div class="col-lg-3 col-md-3 col-sm-3 txt-trad">
                <p style="font-weight: bold">{{ t.name }}</p>
                <p><small>{{ t.btc }}</small></p>
              </div>
            </div>
          </td>
          <td class="col-md-3">{{ t.LastPrice }}</td>
          <td v-if="width > 768" class="col-md-3">
            <img :src="require('../../assets/trade/'+t.chart)" :alt="t.btc"/>
            <small class="sub red">{{ t.percent }}</small>
          </td>
          <td class="col-md-3" style="text-align: center">
            <button class="btnme btn">Trade</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width:0,
      trade: [
        {
          name: "Bitcoin",
          btc: "BTC",
          Logo: "BTC.png",
          LastPrice: "57,173.3",
          chart: "chart.png",
          percent: "0.87"
        },

        {
          name: "Bitcoin",
          btc: "BTC",
          Logo: "BTC.png",
          LastPrice: "57,173.3",
          chart: "chart.png",
          percent: "0.87"
        },

        {
          name: "Bitcoin",
          btc: "BTC",
          Logo: "BTC.png",
          LastPrice: "57,173.3",
          chart: "chart.png",
          percent: "0.87"
        },

        {
          name: "Bitcoin",
          btc: "BTC",
          Logo: "BTC.png",
          LastPrice: "57,173.3",
          chart: "chart.png",
          percent: "0.87"
        },

        {
          name: "Bitcoin",
          btc: "BTC",
          Logo: "BTC.png",
          LastPrice: "57,173.3",
          chart: "chart.png",
          percent: "0.87"
        },

        {
          name: "Bitcoin",
          btc: "BTC",
          Logo: "BTC.png",
          LastPrice: "57,173.3",
          chart: "chart.png",
          percent: "0.87"
        },

        {
          name: "Bitcoin",
          btc: "BTC",
          Logo: "BTC.png",
          LastPrice: "57,173.3",
          chart: "chart.png",
          percent: "0.87"
        },

        {
          name: "Bitcoin",
          btc: "BTC",
          Logo: "BTC.png",
          LastPrice: "57,173.3",
          chart: "chart.png",
          percent: "0.87"
        }
      ]
    }
  },


  "mounted"() {
    this.width = screen.width
  },

}
</script>


<style scoped>
.pos {
  display: flex;
  background: #FFF;
  box-shadow: 7px 0 15px 0 rgb(191, 188, 188);
  border-radius: 0 0 36px 0;
  height: auto;
  font-size: 1.3rem;
}

.btnme {
  background: linear-gradient(98.51deg, #1cbfe2 7.26%, #0f74eb 118.38%);
  border-radius: 7px;
  border: 0;
  color: white;
  font-size: 1.4rem;
  padding: 5px 18px;
}

.btnme:hover {
  background: linear-gradient(98.51deg, #0F74EB -1.56%, #1CBFE2 110.22%);
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}

.sub {
  float: left;
  position: relative;
  left: 23px;
  font-size: 20px;
}

.treader {
  background-color: #f5f5f5;
  height: auto;
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 36px;
  padding: 34px 50px;
  width: 80%;
  margin: 53px auto 120px auto ;
}

td {
  position: relative;
  top: 37px;
}

.red {
  color: #c22611;
}

.trad-pic{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: unset;
}

.txt-trad{
  margin-left:0 !important;
  padding-left: 0 !important;
}

@media only screen and (max-width: 768px) {
  .treader{
    width: 95%;
    padding: 0;
  }

}
</style>