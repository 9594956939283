<template>
  <div class="main-singup">
    <header-form header1="SignUp" header2="Login" selected="header1"/>
    <the-input type="email" placeholder="Email" logo="email"/>
    <the-input :class="{ emailinput: this.show === true , emailinputdiv : this.show === true}" type="password" placeholder="password" logo="password" />
    <div v-if="show === false" class="forget-password">
      <a @click="InviteCode">Invite Code ?</a>
    </div>
    <div v-if="show === true" class="invite-code">
      <span class="text-invite">Invite Code</span>
      <CodeInput :loading="false" class="input-me" v-on:change="onChange" v-on:complete="onComplete" />
    </div>
    <i-am-not-robot/>
    <div class="btn">
      <p class="Terms-text">By signing up I agree to <router-link to="/Terms" class="link-terms">Terms and Conditions</router-link></p>
      <TheButtonForm value="Sign up"/>
      <span class="spn-or">or</span>
      <GoogleButtonLogin value="Sing Up"/>
    </div>
    <have-account/>
  </div>
</template>
<script>

import HeaderForm from "../../Tools/HeaderForm";
import TheInput from "../../Tools/TheInput";
import IAmNotRobot from "../../Tools/IAmNotRobot";
import TheButtonForm from "../../Tools/TheButtonForm";
import GoogleButtonLogin from "../../Tools/GoogleButtonLogin";
import HaveAccount from "./HaveAccount";
import CodeInput from "vue-verification-code-input";

export default {
  name: "Login",
  data(){
    return{
     show:false
    }
  },
  components: {HaveAccount, GoogleButtonLogin, TheButtonForm, IAmNotRobot, TheInput, HeaderForm, CodeInput},
  methods: {
    InviteCode () {
      this.show = true;
    }
  }
}
</script>

<style scoped>

/*---------------------------------General css-------------------------------------------*/


.invite-code {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #0E122B;
  padding: 10px;
  align-items: center;
  border-radius: 0 0 8px 8px;
  margin-bottom: 30px;
}

.emailinput {
  border: none;
  background: #1B1F36;
  color: #CDB5B5;
  font-size: 16px;
  border-radius: 8px 8px 0 0;
  box-shadow: 2px 4px 8px 0 #171A27 inset;
}

.emailinputdiv{
  margin-bottom: 0;
}

.text-invite {
 color: white;
  opacity: 0.7;
  font-size: 12px;
  flex-grow: 2;
}

.input-me{
  flex-grow: 1;
  width: 56% !important;
}

.btn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.Terms-text {
  color: #CDB5B5;
  font-size: 14px;
}

.link-terms {
  color: #568af2 !important;
  text-decoration: underline !important;
}

.spn-or {
  font-size: 14px;
  color: white;
  margin: 10px 0;
}

.main-singup {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.forget-password {
  width: 100%;
  justify-content: flex-start;
  color: #CDB5B5;
  margin-left: 10px;
  margin-bottom: 30px;
  font-size: 14px;
}

.forget-password a {
  cursor: pointer;
}
</style>

<style>
.invite-code .react-code-input  input{
  width: 20px !important;
  height: 20px !important;
  background: #1B1F36;
  box-shadow: inset 2px 4px 8px #171A27;
  border-radius: 8px !important;
  border: 0 !important;
  margin-right: 10px;
  position: relative;
}

</style>