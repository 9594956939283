<template>
  <button class="btn-oranged" @click="$emit('click')">
    <router-link to="" class="link-btn">{{value}}</router-link>
  </button>
</template>

<script>
export default {
  props:['value'],
  name: "ThecustomButton"
}
</script>

<style scoped>
.btn-oranged {
  background-color: #FF5107;
  border-radius: 10px;
  border: none;
  color:white !important;
  flex-basis: 117px;
  padding: 5px 20px;
  text-decoration: none;
  font-size: 14px;
}

.btn-oranged a {
  color:white !important;
  text-decoration: none;
}
</style>