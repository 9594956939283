<template>
  <div class="MyAssets box-dashbord">
    <div class="box-1">
      <header class="header-me">
        <h2>My Assets</h2>
      </header>

      <div class="assets-diteal">
        <span class="span-txt">Total Balance</span>
        <span class="span-num"> $ {{ Assets }}</span>
      </div>

      <div class="balanced">
        <span class="blc">Balance Details</span>
      </div>
    </div>

    <div class="box-2">
      <img class="chart" src="../../assets/Dashbord/chart.png" alt="">
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      Assets: 13490.38,
      //this setting apex chart
      options: {
        colors: ['#0CB746', '#0029FE', '#1396F5', '#FE8900'],
        dataLabels: {
          enabled: false,
        }
      },
      //this data apex chart
      series: [25, 25, 25, 25,]
    }
  },
  name: "MyAssets"
}
</script>

<style scoped>
.MyAssets {
  display: flex;
  flex-basis: 100px;
  flex-wrap: nowrap;
}

.header-me {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  color: white;
}

.span-txt {
  color: #CDB5B5;
  font-size: 14px;
}

.chart {
  width: 300px;
  height: auto;
}

.span-num {
  font-size: 18px;
  color: white;
}

.assets-diteal {
  display: flex;
  flex-direction: column;
}

.balanced {
  display: flex;
  margin-top: 30px;
}

.blc {
  font-size: 14px;
  color: #FF5107;
}

.box-2 {
  display: flex;
  justify-content: flex-end;
  align-items: self-start;
  flex-basis: 60%;
}

.box-1 {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
}

@media only screen and (max-width: 600px) {
  .MyAssets {
    justify-content: center;
  }
}

</style>

<style>


</style>