<template>
  <div class="box-dashbord overview">
    <div class="box-1">
      <header class="header-me">
        <h2>Account Overview</h2>
      </header>
      <div v-for="n in user" :key="n.name" class="userData">
        <span>Name</span>
        <h5>{{ n.name }}</h5>
      </div>

      <div v-for="e in user" :key="e.email" class="userData">
        <span>Email</span>
        <h5>{{ e.email }}</h5>
      </div>

      <div v-for="p in user" :key="p.phone" class="userData">
        <span>phone</span>
        <h5 class="not-verify" v-if="verify">Verify</h5>
        <h5 v-if="!verify">{{ p.phone }}</h5>
      </div>

    </div>
    <div class="box-2">
      <div class="header-me">
        <div class="item1">
        <h4 class="Status">Verification Status</h4>
        </div>
        <div class="item2">
         <orangbtn  value="unverified"/>
        </div>
      </div>

      <div v-for="l in user" :key="l.accountlevel" class="useraccountlevel">
        <span>Account Level</span>
        <h5>Level {{ l.accountlevel }}</h5>
      </div>

      <div v-for="v in user" :key="v.tradingvolume" class="TradingVolume">
        <div class="item1">
          <span class="Status">30-day Trading Volume</span>
          <h5 class="usd">{{ v.tradingvolume }} USD</h5>
        </div>

        <div class="item2">
          <span class="Status">Fee</span>
          <h5 class="usd">{{ v.fee }}%</h5>
        </div>
      </div>
      <div class="Levels">
        <h4 class="Account-Levels">Account Levels</h4>
      </div>
    </div>

  </div>
</template>

<script>
import Orangbtn from "../Tools/orangbtn";

export default {
  name: "AccountOverview",
  components: {Orangbtn},
  data() {
    return {
      verify: true,

      user: [
        {
          name: 'Hadi Moradi',
          email: 'hm646036@gmail.com',
          phone: '09196087751',
          accountlevel: 2,
          tradingvolume: 5000000,
          fee: 0.04
        }
      ]
    }

  }
}
</script>

<style scoped>
.overview{
  flex-grow: 3;
}
.Account-Levels {
  color: #FF5107;
  margin-top: 15px;
}

.TradingVolume {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.item1, .item2 {
  flex-grow: 1;
}

.usd {
  color: white;
}

.useraccountlevel {
  color: white;
}


.Status {
  color: #FACEBC;
}

.not-verify {
  color: #FF5107;
}

.userData {
  display: flex;
  flex-direction: column;
  color: #CDB5B5;
  margin-bottom: 10px;
}

.box-1 {
  flex-grow: 1;
  flex-direction: column;
  color: white;
  margin-top: 20px;

}

.box-2 {
  flex-grow: 1;
  flex-direction: column;
  margin-top: 20px;
}

.header-me {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

}
</style>