<template>
  <div class="Referrals box-dashbord">
    <div class="header">
      <h2>Your Referrals</h2>
    </div>

    <div class="numbers">
      <div class="num1">
        <span class="number">{{ Referrals }}</span>
        <span class="text">Your Referrals</span>
      </div>

      <div class="num2">
        <span class="number">{{ Rewards }}</span>
        <span class="text">Your Rewards</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Referrals",
  data() {
    return {
      Referrals: 0,
      Rewards: 0
    }
  }
}
</script>

<style scoped>
.Referrals {
  color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header{
  margin-bottom: 50px;
}

.num1 {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}

.num2 {
  display: flex;
  flex-direction: column;
}

.numbers{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.number{
  font-size: 40px;
}

.text{
  font-size: 16px;
}
</style>