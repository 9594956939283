import Home from "../../components/homepage/home.vue";
import Auth from "../../components/Auth/Auth.vue";
import Login from "../../components/Auth/Login/Login.vue";
import ForgetPassWord from "../../components/Auth/ForgetPassword/ForgetPassWord.vue"
import ResetPassword from "../../components/Auth/RestPassword/ResetPassword";
import Signup from "../../components/Auth/SingUp/Signup";
import AboutUs from "../../components/About/About";
import Terms from "../../components/Terms/Terms";
import FAQ from "../../components/FAQ/FAQ";
import Dashbord from "../../components/Dashbord/Dashbord";
import OrderHistory from "../../components/History/OrderHistory"
import LoginHistory from "../../components/History/LoginHistory"
import Crypto from "../../components/Wallet/Crypto"
import Balance from "../../components/Balance/Balance"
import Veryfication from "../../components/KYC/Veryfication"
import Verify from "../../components/KYC/TheVeryfiy"
import VerificatIonid from "../../components/KYC/VerificatIonid"
import VerificatIonSubmited from "../../components/KYC/VerificatIonSubmited"
import VerificatIonEdite from "../../components/KYC/VerificatIonEdite"
import CreditCart from "../../components/KYC/CreditCart"
import Notification from "../../components/Notifications/Notification"
import Trade from "../../components/Trade/Trade"
export default [
    {
        path: '/',
        name: 'Home',
        component: Home
    },

    {
        path: '/aboutus',
        name: AboutUs,
        component: AboutUs
    },
    {
        path: '/terms',
        name: Terms,
        component: Terms
    },
    {
        path: '/orderhistory',
        name: 'OrderHistory',
        component: OrderHistory
    },
    {
        path: '/loginhistory',
        name: 'LoginHistory',
        component: LoginHistory
    },
    {
        path: '/balance',
        name: 'Balance',
        component: Balance
    },

    {
        path: '/crypto',
        name: 'Crypto',
        component: Crypto
    },
    {
        path: '/faq',
        name: FAQ,
        component: FAQ
    },
    {
        path: '/dashbord',
        name: Dashbord,
        component: Dashbord
    },
    {
        path: '/creditcart',
        name: 'CreditCart',
        component: CreditCart
    },
    {
        path: '/notification',
        name: 'Notification',
        component: Notification
    },
    {
        path: '/trade',
        name: 'Trade',
        component: Trade
    },

    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login
            },
            {
                path: 'forgetpassword',
                name: 'ForgetPassword',
                component: ForgetPassWord
            },
            {
                path: 'resetpassword',
                name: 'ResetPassword',
                component: ResetPassword
            },
            {
                path: 'singup',
                name: 'SingUp',
                component: Signup
            },

        ]
    },

    {
        path: '/kyc',
        name: 'KYC',
        component: Veryfication,
        children: [
            {
                path: 'verify',
                name: 'Verify',
                component: Verify
            },
            {
                path: 'verificationid',
                name: 'VerificatIonid',
                component: VerificatIonid
            },
            {
                path: 'verificationsubmited',
                name: 'VerificatIonSubmited',
                component: VerificatIonSubmited
            },
            {
                path: 'verificationedite',
                name: 'VerificatIonEdite',
                component: VerificatIonEdite
            },

            {
                path: '/kyc',
                redirect :'/kyc/verify'
            }

        ]
    },


];