<template>
  <div class="main">
    <div class="top-div">
      <div class="item-1">
        <Total/>
        <Cyrpto/>
      </div>
      <div class="item-2">
        <Cards/>
      </div>
    </div>
    <div class="bottom-div">
      <CryptoTransactions />
    </div>
  </div>
</template>

<script>
import Total from "./Total";
import Cyrpto from "./Cyrpto";
import Cards from "./Cards";
import CryptoTransactions from "./CryptoTransactions"
export default {
  name: "Balance",
  components: {CryptoTransactions,Cards, Cyrpto, Total}
}
</script>

<style scoped>
.main{
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: white;
  background-color: #040F26;
}

.top-div{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  width: 95%;
  margin: 0 auto;
}

.bottom-div{
  display: flex;
  width: 95%;
  margin: 0 auto;
}

.item-1{
  flex-grow: 2;
  flex-basis: 100px;
  gap: 17px;
  display: flex;
  flex-direction: column;
}

.item-2{
  flex-grow: 1;
  flex-basis: 50px;
}
</style>