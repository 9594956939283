<template>
  <div class="card">
    <div class="padding">
      <div class="header">
        <span class="title">Your Cards</span>
        <span><a class="add-cart" href="#">Add Card</a></span>
      </div>

      <div class="credit-card">
        <carousel :items=1 :nav="false" style="width:100%;max-width: 300px">
          <TheCreditCart :dataCart="dataCart" v-for="cart in dataCart" :key="cart.name"/>
        </carousel>
      </div>

      <div class="Credit-Transactions">
        <div class="title">
          <span class="title-text">Credit Transactions</span>
        </div>
        <TheTransactions :item="data" v-for="(data,index) in data" :key="index.name"/>
      </div>
    </div>
  </div>
</template>

<script>
import TheTransactions from "../Tools/TheTransactions";
import TheCreditCart from "../Tools/TheCreditCart"
import carousel from "vue-owl-carousel";

export default {
  name: "Cards",
  components: {TheTransactions, TheCreditCart, carousel},
  data() {
    return {
      data: [
        {
          time: '4/5/21 05:12',
          amount: 12.45,
          numberCart: '0000 0000 0000 0000',
          logo: 'red'
        },
        {
          time: '4/5/21 05:12',
          amount: 12.45,
          numberCart: '0000 0000 0000 0000',
          logo: 'red'
        },
        {
          time: '4/5/21 05:12',
          amount: 12.45,
          numberCart: '0000 0000 0000 0000',
          logo: 'yellow'
        },
        {
          time: '4/5/21 05:12',
          amount: 12.45,
          numberCart: '0000 0000 0000 0000',
          logo: 'yellow'
        },
      ],

      dataCart: [
        {
          name: 'HadiMoradi',
          numberCart: '0000 0000 0000 0000',
          date: '4/5/21 05:12'

        },
        {
          name: 'AliMoradi',
          numberCart: '0000 0000 0000 0000',
          date: '4/5/21 05:12'

        },
      ]

    }
  },
}
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #313754;
  border-radius: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
}

.title {
  font-size: 16px;
}

.add-cart {
  font-size: 12px;
  text-decoration: none;
  color: #FF5107;
}

.credit-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Credit-Transactions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.padding {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 10px auto;
}

.vis-card {
  width: 100%;
}
</style>