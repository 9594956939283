<template>
  <div class="main-market-info">
    <div class="top-div-market" v-if="show === false">
      <div class="header-market-info">
        <img @click="show = true" src="../../assets/trade/navbar-icon.png" alt="">
        <span class="market-info-title">BTC/USDT</span>
      </div>
      <div class="btc-usd">
        <span class="btc">57,173.3</span>
        <span :class="{'green-number': ETC > 0 , 'red-Number' : ETC < 0}">{{ ETC }}</span>
      </div>
      <div class="items">
        <div class="volume">
          <span class="title">volume</span>
          <span class="value">14,189</span>
        </div>
        <div class="high">
          <span class="title">high</span>
          <span class="value">14,189</span>
        </div>
        <div class="low">
          <span class="title">low</span>
          <span class="value">14,189</span>
        </div>
      </div>
      <div class="Balance">
        <span class="title-balance">
          Your Balance
        </span>
        <div class="balance-item-1">
          <span class="title">BTC</span>
          <span class="value">USDT</span>
        </div>
        <div class="balance-item-2">
          <span class="title">0.345</span>
          <span class="value">425</span>
        </div>
      </div>
    </div>


    <!----------------------------------------- menu trade -------------------------------->

    <div class="trade-menu" v-if="show === true">
      <div class="padding-div">
        <div class="header-menu">
          <button  class="menu-btn">U$D</button>
          <img @click="show = false" src="../../assets/trade/menu-bar.png" alt="">
        </div>
        <div class="coins" v-for="i in item" :key="i.name">
          <div class="logo">
            <img  :src="require('../../assets/trade/'+i.logo)" alt="">
            <span>{{i.btc}}</span>
          </div>
          <div class="name">
            <p>{{ i.name }}</p>
            <p>{{i.price}}</p>
          </div>
          <div class="fee">{{i.fee}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const a = {
  bitcoin : 'bitcoin.png',
  ethernum : 'ethernum.png',
  ripple : 'ripple.png',
  dash : 'dash.png'
}
export default {
  name: "MarketInfo",
  data() {
    return {
      ETC: 0.87,
      show: false,
      item:[
        {name:'Bitcoin', price:'57,173.3',fee:0.87,btc:'BTC', logo:'bitcoin.png'},
        {name:'etherum', price:'57,173.3',fee:0.87,btc:'ETH',logo:'ethernum.png'},
        {name:'ripple', price:'57,173.3',fee:0.87,btc:'XRP', logo:'ripple.png'},
        {name:'dash', price:'57,173.3',fee:0.87,btc:'Dash', logo:'dash.png'},
      ]
    }
  },
  computed:{
    icon(){
      return a[this.item.logo]
    }
  },
}
</script>

<style lang="scss" scoped>

.main-market-info {
  display: flex;
  gap: 51px;
  flex-grow: 1;
  flex-direction: column;
  position: relative;

  .green-number {
    color: #06F148;
    font-size: small;

    &::before {
      content: '+';
      margin-right: 5px;
    }
  }

  .red-Number {
    color: #E21C7B;
    font-size: small;

    &::before {
      content: '-';
      margin-right: 5px;
    }
  }

  .top-div-market {
    display: flex;
    flex-direction: column;
    gap: 17px;
  }

  .header-market-info {
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 10px;

    .market-info-title {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .btc-usd {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
    flex-grow: 1;
    align-items: center;

    .btc {
      font-size: large;
      color: #1CBFE2

    }

    .usd {
      font-size: small;
    }
  }

  .basic-item {
    display: flex;
    flex-direction: column;
  }


  .items {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .title {
      color: #8A8A8A;
      font-size: small;
    }

    .value {
      color: white;
      font-size: large;
    }

  }

  .volume {
    @extend .basic-item
  }

  .high {
    @extend .basic-item
  }

  .low {
    @extend .basic-item
  }

  .Balance {
    display: flex;
    background-color: rgba(75, 80, 90, 0.32);
    border-radius: 0 0 20px 20px;
    padding: 20px;
    flex-direction: column;
    gap: 10px;

    .title-balance {
      font-size: large;
    }

    .balance-item-1 {
      display: flex;
      justify-content: space-between;
      font-size: medium;
    }

    .balance-item-2 {
      display: flex;
      justify-content: space-between;
      font-size: medium;
    }
  }

  .trade-menu {
    display: flex;
    flex-direction: column;
    background-color: #040F26;
    width: 100%;
    height: 100%;
    border-radius: 0 20px 20px 0;

    .padding-div {
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 10px;

      .header-menu {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        justify-content: space-between;

        .menu-btn{
          border: 0;
          border-radius: 8px;
          background-color: #0D722A;
          color: white;
          width: 40%;
          height: 100%;
          font-size: small;
          background-image: url("../../assets/trade/Arrow-right.png");
          background-position: 90% 50%;
          background-repeat: no-repeat;
          text-align: left;
        }
      }

      .basic-coin-div{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
      }

      .coins {
        display: flex;
        flex-direction: row;
        align-items: start;
        gap: 20px;

       .logo {
          @extend .basic-coin-div;
          font-size: small;
           gap: 13px;
        }

        .name {
          @extend .basic-coin-div;
          font-size: medium;
        }

        .fee {
          @extend .basic-coin-div;
          font-size:small ;
        }
      }
    }
  }
}


</style>