<template>
  <div  class="main-modal" @click.self="$emit('close')">
    <div class="modal">
      <a @click="$emit('close')"><img class="close" src="../../assets/Dashbord/close.png" alt=""></a>
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: ['value'],
  name: "TheModal",
  data(){
    return{

    }
  }
}
</script>

<style scoped>
.main-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  inset: 0;
  background: #1322468f;
}

.modal {
  background-color: #313754;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: max-content;
  padding: 30px 50px;
  height: auto;
  position: relative;
}

.close {
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>