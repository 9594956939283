<template>
  <div class="main">
    <img class="bitcoin" :src="require('../../assets/Wallet/'+icon)" alt="">
      <div class="data-bit">
        <div class="data">
          <div class="name">
            <span class="name-span">{{ item.name }}</span>
          </div>
          <div class="btc">
            <span class="btc-text">{{ item.btc }} BTC</span>
          </div>
          <div class="usd">
            <span class="usd-text">{{ item.usd }} USD</span>
          </div>
        </div>
        <div class="qrcode">
          <div class="img">
          <img class="qr-img" src="../../assets/Wallet/qr_code_lit.png" alt="">
          </div>
          <div class="span-address">
          <span class="span-address-qr">View Address</span>
          </div>
        </div>
      </div>
      <div class="buy">
        <div class="btn-me">
          <ThecustomButton @click="$emit('withdraw' , item)" value="Withdraw"/>
        </div>
        <div class="available-balanced">
          <span class="balanced-text">Availabe Balance :{{ item.availabebalance }}</span>
        </div>
      </div>
    </div>
</template>

<script>
const a = {
  bitcoin : 'bitcoin.png',
  etherum : 'etherum.png',
  ripple : 'ripple.png',
}
import ThecustomButton from "./ThecustomButton";
export default {
  name: "TheWallet",
  components: {ThecustomButton},
  props: ['item'],
  data() {
    return {}
  },

  methods:{
    Withdraw:function (){

    }
  },

  computed:{
      icon(){
      return a[this.item.logo]
    }
  },
}
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: auto;
  background-color: #081734;
  border-radius: 9px;
  color: white;
  padding: 20px;
  flex-basis: 350px;
  position: relative;
  margin: 39px 20px;
}

.data-bit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px 25px;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.data {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  line-height: 2.5;
}

.qrcode {
  flex-grow: 1;
  display: flex;
  align-items: end;
  flex-direction: column;
}

.buy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.btn-me {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
}

.available-balanced {
  flex-grow: 2;
  display: flex;
  justify-content: flex-end;
}

.name-span {
  color: white;
  font-size: 14px;
}

.btc-text {
  color: white;
  font-size: 16px;
}

.usd-text {
  color: white;
  font-size: 13px;
}

.balanced-text {
  opacity: 0.7;
  font-size: 12px;
}

.qr-img{
  width: 100%;
}

.span-address-qr{
  text-align: center;
}

.img{
  width: 86px;
  height: 86px;
  background-color: #C4C4C4;
  border-radius: 10px;
}

.span-address{
  display: flex;
  width: 60%;
  justify-content: center;
  margin-top: 10px;
}

.bitcoin{
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-19px , -30px);
}
</style>