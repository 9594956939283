<template>
  <div>
    <div class="main">
      <div class="header">
        <p class="tittle">{{ tittle1 }}</p>
        <router-link to="VerificatIonid"><img class="arrow" src="../../assets/KYC/Arrow.png" alt="Arrow"></router-link>
      </div>
      <div class="text">
        <p class="first-line">{{ txt1 }}</p>
        <p class="second-line">{{ txt2 }}</p>
      </div>
      <div v-if="state.userInfo.personal" class="Submitted">
        <orangbtn value="Submitted to be audited" @click="submit"/>
      </div>
    </div>

    <div class="main">
      <div class="header">
        <p class="tittle">{{ tittle2 }}</p>
        <img class="arrow" src="../../assets/KYC/Arrow.png" alt="Arrow">
      </div>
      <div class="text">
        <p class="first-line">{{ txt1 }}</p>
        <p class="second-line">{{ txt2 }}</p>
      </div>
    </div>

    <div class="main">
      <div class="header">
        <p class="tittle">{{ tittle3 }}</p>
        <img class="arrow" src="../../assets/KYC/Arrow.png" alt="Arrow">
      </div>
      <div class="text">
        <p class="first-line">{{ txt1 }}</p>
        <p class="second-line">{{ txt2 }}</p>
      </div>
    </div>

  </div>
</template>

<script>
import Orangbtn from "../Tools/orangbtn";

export default {
  components: {Orangbtn},
  name: "TheVeryfiy",
  data() {
    return {
      tittle1: 'Identity Verification',
      tittle2: 'Contact Info Verification',
      tittle3: 'Credit Info Verification',
      txt1: 'Verify or edit your personal information that you submited at Warex',
      txt2: 'Nationality,Name,ID card upload....',
      show: false,
    }
  },

  methods:{
    submit(){
      this.$router.push({name : 'VerificatIonSubmited'})
    }
  }
}
</script>

<style scoped>
.main {
  background-color: #1E2A4B;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 30px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-size: 16px;
  align-items: first baseline;
}

.arrow {
  width: 13px;
  height: 16px;
  cursor: pointer;
}

.text {
  display: flex;
  flex-direction: column;
}

.first-line {
  font-size: 14px;
  color: #CDB5B5;
}

.second-line {
  font-size: 12px;
  color: #929292;
}

.Submitted {
  margin-left: auto;
}
</style>