<template>
<div class="start">
<div class="left-div">
  <div class="h2">
  <h2>Ready to get started?</h2>
  </div>
  <div class="paraph">
  <p>Login to your account or create a new one</p>
  </div>
</div>
  <div class="right-div ">
    <router-link class="btn btnme" to="/auth/login">login</router-link>
    <router-link class="btn btnme" to="/auth/singup">singup</router-link>
  </div>
</div>
</template>

<script>
export default {
  name: "readystart"
}
</script>

<style scoped>
.btnme {
  background: linear-gradient(98.51deg, #1cbfe2 7.26%, #0f74eb 118.38%);
  border-radius: 7px;
  border: 0;
  color: white;
  font-size: 1.4rem;
  padding: 10px 18px;
  width: 35%;
  height: max-content;
  margin: 19px;
}

.btnme:hover{
  background: linear-gradient(98.51deg, #0F74EB -1.56%, #1CBFE2 110.22%);
}

.btnme2 {
  background: linear-gradient(98.51deg, #350DD1 7.26%, #0F74EB 118.38%);
  border-radius: 7px;
  border: 0;
  color: white;
  font-size: 1.4rem;
  padding: 10px 18px;
  width: 35%;
  height: max-content;
  margin: 19px;
}

.btnme2:hover{
  background: linear-gradient(98.51deg, #350DD1 -1.56%, #1CBFE2 110.22%);;
}

.h2{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.paraph{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.start{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 70%;
  margin: 50px auto 50px auto;
  background: #FFFFFF;
  box-shadow: -3px 6px 12px rgba(162, 153, 153, 0.31);
  border-radius: 18px;
}

.right-div{
  display: flex;
  flex-grow: 1;
  flex-basis: 200px;
  flex-direction: row;
  margin: 29px 24px;
}

.left-div{
  display: flex;
  flex-grow: 1;
  flex-basis: 200px;
  flex-direction: column;
  margin: 29px 24px;
}
</style>