<template>
  <the-modal @close="$emit('close')">
    <div>
      <div class="header">
        <span>Change Password</span>
      </div>
      <TheInput logo="password" placeholder="Current Password" type="password"/>
      <TheInput logo="password" placeholder="New Password" type="password"/>
      <TheInput logo="password" placeholder="Current Repeat New Password" type="password"/>
      <div class="modal-btn">
        <button @click="show =!show" class="modalBtn btn btn-block">Change</button>
      </div>
    </div>
  </the-modal>
</template>

<script>
import TheInput from "../../Tools/TheInput";
import TheModal from "../../Tools/TheModal";
export default {
  name: "ChangePassword",
  components: {TheModal, TheInput},
  data(){
    return{
      show:false
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.header span {
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.modal-btn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalBtn{
  background: #FF5107;
  border-radius: 7px;
  border: 0;
  padding: 10px;
  color: white;
  font-size: 14px;
}


</style>