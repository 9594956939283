<template>
  <div class="body">
    <div class="text-body" v-for="t in text" :key="t.title">
      <h4 class="title" :id="t.title">{{ t.title }}</h4>
      <p class="text">{{ t.text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BodyTerms",
  props:['text'],
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 5px auto;
}

.text-body {
  display: flex;
  flex-direction: column;
  margin: 20px auto;
}

.text {
  font-size: 14px;
  font-weight: 500;
}

.title{
  font-weight: bold;
}

</style>