<template>
<div class="Account">
  <p class="text">Already have an account?</p>
  <router-link to="/auth/login" class="link">Log in</router-link>
</div>
</template>

<script>
export default {
  name: "HaveAccount"
}
</script>

<style scoped>
.Account{
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.text{
  color: white;
  font-size: 14px;
}

.link{
  font-size: 13px;
  text-decoration: none;
}
</style>