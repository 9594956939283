<template>
  <div class="main">
    <div class="order-history">
      <div class="header">
        <div class="tittle-text">
          <span class="tittle">Crypto Transactions</span>
        </div>
      </div>

      <div class="table">
        <table class="table table-borderless">
          <tr>
            <th>Time</th>
            <th>Type</th>
            <th v-if="width > 768">Crypto</th>
            <th>Amount</th>
            <th v-if="width > 768">From/To</th>
            <th v-if="width > 768">Transaction ID</th>
            <th v-if="width > 768">Status</th>
          </tr>

          <tr v-for="d in data" :key="d[0]">
            <td>{{ d.OrderTime }}</td>
            <td :class="{'red':  d.active === false , 'green' : d.active === true}">{{ d.Type }}</td>
            <td v-if="width > 768">{{ d.Pair }}</td>
            <td>{{ d.Amount }}</td>
            <td v-if="width > 768">{{ d.Fee }}</td>
            <td>{{ d.Total }}</td>
            <td v-if="width > 768" v-show="d.Status === true" >
              <apexchart width="55%" type="donut" :options="options" :series="series"></apexchart>
            </td>
            <td v-if="width > 768" v-show="d.Status === false" >
              <img src="../../assets/History/block.png" alt="">
            </td>
          </tr>

        </table>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: "OrderHistory",
  data() {
    return {
      width: 0,
      data: [
        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Fee: 0.04,
          Status: true,
          active:true
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Fee: 0.04,
          Status: true,
          active:true
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Fee: 0.04,
          Status: false,
          active:true
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Fee: 0.04,
          Status: false,
          active:false
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Fee: 0.04,
          Status: true,
          active:false
        },

      ],

      options: {
        colors: ['#1CFF77'],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        chart: {
          offsetX: -25
        },
      },

      series: [100],
    }
  },

  "mounted"() {
    this.width = screen.width
  },

}
</script>

<style scoped>
.red{
  color: #FF5107;
}

.green{
  color: #FFD600;
}
.main {
  display: flex;
  justify-content: center;
  background-color: #040F26;
  align-items: center;
}

.order-history {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #1E2A4B;
  width: 100%;
  border-radius: 16px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px;
}

.tittle {
  font-size: 18px;
  color: white;
}

.filter {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  font-size: 14px;
  color: white;
  justify-content:center;
  flex-wrap: wrap;
  gap: 50px 70px;

}


.date {
  display: flex;
  flex-direction: row;
}

.input-date {
  background-color: #1B1F36;
  border-radius: 8px;
  margin-left: 10px;
  padding: 5px;
  position: relative;
  bottom: 5px;
  opacity: 0.7;
}

a {
  color: white;
  opacity: 0.7;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  margin-right: 20px;
}

.link-me:checked {

}

.link-me:hover {
  cursor: pointer;
}

.link-me {
  opacity: 1;
  font-size: 16px;
}

.link-me::after {
  content: "";
  position: absolute;
  width: 55%;
  background-color: #26FFFF;
  height: 3px;
  bottom: 0;
  top: 20px;
  left: 0;
  border-radius: 20px;
}

.table{
  width: 90%;
  max-width: 100%;
  background-color: transparent;
  margin: 10px auto;

}

table {
  background-color: transparent !important;
  color: white;
  font-size: 12px;
  margin-top: 20px;
  position: relative;
  table-layout: fixed;
  padding-left: 53px;
}

table th {
  border: none;
  color: #EDEDED !important;
  font-weight: 100;
  padding: 0;
}

.table td {
  border: none;
}


.table th, .table td {
  vertical-align: middle;
  padding: 5px !important;
}

@media (max-device-width: 420px) {
  .filter {
    justify-content: flex-start;
  }
}

/*.date{*/


/*}*/

/*.order-type{*/


/*}*/

/*.coin{*/


/*}*/

/*.btn-me{*/


/*}*/


</style>