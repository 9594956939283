<template>
  <div class="main-login">
    <header-form header1="Login" header2="SignUp" selected="header1"/>
    <the-input type="email" placeholder="Email" logo="email"/>
    <the-input type="password" placeholder="password" logo="password" :style="{ marginBottom: 5 +'px'}"/>
    <div class="forget-password">
      <a @click="GotoForgetPassword">Forget Password ?</a>
    </div>
    <i-am-not-robot/>
    <div class="btn">
      <TheButtonForm value="Login"/>
      <span class="spn-or">or</span>
      <GoogleButtonLogin value="Login"/>
    </div>
  </div>
</template>
<script>

import HeaderForm from "../../Tools/HeaderForm";
import TheInput from "../../Tools/TheInput";
import IAmNotRobot from "../../Tools/IAmNotRobot";
import TheButtonForm from "../../Tools/TheButtonForm";
import GoogleButtonLogin from "../../Tools/GoogleButtonLogin";

export default {
  name: "Login",
  components: {GoogleButtonLogin, TheButtonForm, IAmNotRobot, TheInput, HeaderForm},
  methods:{
    GotoForgetPassword(){
      this.$router.push('/auth/forgetpassword')
    }
  }
}
</script>

<style scoped>

/*---------------------------------General css-------------------------------------------*/
.btn {
  display: flex;
  flex-direction: column;
  width: 100%;
}


.spn-or {
  font-size: 14px;
  color: white;
  margin: 10px 0;
}

.main-login {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.forget-password{
  width: 100%;
  justify-content: flex-start;
  color: #CDB5B5;
  margin-left: 10px;
  margin-bottom: 30px;
  font-size: 14px;
}

.forget-password a{
  cursor: pointer;
}
</style>