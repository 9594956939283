<template>
  <div class="insert-image">
    <div class="img">
      <img class="su-img" src="../../assets/KYC/NIC.png" alt="">
      <img class="check" src="../../assets/Login/check.png" alt="">
    </div>
    <div class="img">
      <img class="su-img" src="../../assets/KYC/NIC.png" alt="">
      <img class="check" src="../../assets/Login/check.png" alt="">
    </div>

  </div>
</template>

<script>
export default {
  props: ['title', 'alert'],
  name: "Theinsertimage"
}
</script>

<style scoped>
.insert-image {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.img {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background: #1B1F36;
  box-shadow: inset 2px 4px 8px #171A27;
  border-radius: 8px;
  padding: 20px;
  flex-basis: 30%;
}


.check{
  width: 10%;
}

</style>