<template>
  <div class="main-log" :class="{'open' : select}">
    <div class="first-row">
      <div class="title">
        <inline-svg
            :src="require('../../assets/Notifications/'+icon)"
            :class="{current : !select}"
        >
        </inline-svg>
        {{title}}
      </div>
      <div class="date">{{date}}</div>
    </div>
    <div class="second-row">
      <div :class="{'overflow': select === true}" class="log">{{ log }}</div>
      <div class="arrow">
        <img class="arrow-pic" @click="select = true" v-if="!select" src="../../assets/Notifications/arrow.png" alt="">
        <img class="arrow-pic" @click="select = false" v-if="select" src="../../assets/Notifications/arrow-select.png" alt="">
      </div>
    </div>
    <div :class="{borderSelect : select , borderBottom : !select}" ></div>
  </div>
</template>

<script>
const a ={
  enter:'enter.svg',
  newip : 'newip.svg',
  new : 'new.svg'
}

export default {
  name: "Log",
  props:['logo','title','log','date'],
  data(){
    return{
     select:false
    }
  },

  computed:{
    icon(){
      return a[this.logo]
    }
  },

}
</script>

<style lang="scss" scoped>

.current{
  color: #FF5107;
}

 .borderSelect{
   border-bottom: 1px solid #FF5107;
 }

.main-log {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 10px;
}

.borderBottom{
  border-bottom: 1px solid #F1F1F1;
}

.first-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.second-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title{
  font-size: medium;
  font-weight: bold;
  display: flex;
  gap: 10px;
  align-items: end;
}

.log{
  font-size: small;
  height: 16px;
  overflow: hidden;
}

.overflow{
  height: auto;
  overflow: auto;
}

.date{
  font-size: small;
}

.arrow-pic{
  cursor: pointer;
}

.main-log{
  .first-row{
    .title{
      svg{
        fill:white;
      }
    }
  }
  &.open{
    .first-row{
      .title{
        svg{
          fill:#FF5107;
        }
      }
    }
  }
}
</style>