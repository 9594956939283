<template>
  <button class="btn google-login-btn">
    {{value}} in with Google
    <img src="../../assets/Login/googleicon.png" alt="" class="google-icon">
  </button>
</template>

<script>
export default {
  props:['value'],
  name: "GoogleButtonLogin",
}
</script>

<style scoped>
.google-login-btn{
  padding: 10px;
  color: white;
  font-size: 14px;
  background: transparent;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: 1px solid;
}

.google-icon{
  position: absolute;
}
</style>