<template>
<div class="insert-image">
<div class="img">
  <img src="../../assets/KYC/sample.png" alt="">
</div>
  <div class="insert-img">
    <p class="title">{{title}}</p>
    <label class="btn btn-default btn-file input-file">
      Choose File + <input type="file" style="display: none;" required>
    </label>
  </div>
  <div v-if="alert" class="alert-div">
  <p class="alert">
    *Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i
  </p>
  </div>
</div>
</template>

<script>
export default {
  props:['title','alert'],
  name: "Theinsertimage"
}
</script>

<style scoped>
.input-file{
  background: transparent;
  font-size: 14px;
  color: #6F6767;
  text-decoration: none;
  border: 0;
}
.insert-image{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.img{
  flex-grow: 1;
  flex-basis: 100px;
}

.img img{
  width: 100%;
  height: 160px;
}

.insert-img{
  background: #1B1F36;
  box-shadow: inset 2px 4px 8px #171A27;
  border-radius: 8px;
  flex-grow: 1;
  flex-basis: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 41px;
  padding: 20px;
}

.title{
  font-size: 14px;
  color: #CDB5B5;
  margin-right: auto;
}

.ChooseFile{
  font-size: 12px;
  color: #6F6767;
  text-decoration: none;
}

.alert{
  color: #ED4C5C;
  font-size: 12px;
}

.alert-div{
  display: flex;
}
</style>