<template>
  <div class="personal-info">
    <div class="padding">
    <div class="header">
      <p class="title">Personal Info</p>
    </div>

    <div class="form-select">
      <select class="select-custom form-select">
        <option v-for="opt in options" :key="opt">{{ opt }}</option>
      </select>
    </div>

      <div class="inputs-cart-info">
        <div class="item-input-1">
          <TheInput placeholder="Name"/>
        </div>
        <div class="item-input-2">
          <TheInput placeholder="Family"/>
        </div>
      </div>

    <div class="header">
      <p class="title">ID Verification</p>
    </div>

    <div class="typeId">
      <div class="left-typeId">
        <p class="title-type">Type of ID</p>
        <div class="text-type">
          <p class="pass-txt">Passport</p>
          <p class="idcart-txt">Government-issued Card</p>
          <TheInput type="text" placeholder="ID Document Number"/>
        </div>
      </div>
    </div>

    <div class="insert-pic">
      <Theinsertimage title="Front of ID Document" alert='
     *Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor i
     '/>
    </div>

    <div class="text-caption">
      <span class="txt-one">Statement Verification</span>
      <span class="txt-one">
        Upload a photo of you handholding your ID and handwritten statement:
        “ٌَWAREX WAREX WAREX”
      </span>
    </div>

    <div class="insert-pic">
      <Theinsertimage title="Front of ID Document"/>
    </div>


    <TheButtonForm  value="Submit" @click="submit"/>
    </div>
  </div>
</template>

<script>
import TheInput from "../Tools/TheInput";
import Theinsertimage from "../Tools/Theinsertimage";
import TheButtonForm from "../Tools/TheButtonForm";

export default {
  name: "VerificatIonid",
  components: {TheButtonForm, Theinsertimage, TheInput},
  data() {
    return {
      options: ['Iran', 'Iraq', 'Germany'],
    }
  },

  methods:{
    submit(){
      this.state.userInfo.personal = true
      this.$router.push({name : 'Verify'})
    }
  }
}
</script>

<style scoped>
.personal-info {
  display: flex;
  flex-direction: column;
  background: #1E2A4B;
  border-radius: 29px;

}

.inputs-cart-info{
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.item-input-1{
  flex-grow: 1;
  flex-basis: 100px;
}

.item-input-2{
  flex-grow: 1;
  flex-basis: 100px;
}

.padding{
  display: flex;
  flex-direction: column;
   width: 95%;
  margin: 20px auto;
  gap: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.title {
  font-size: 14px;
  color: #CDB5B5;
}

.form-select {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.select-custom {
  width: 100%;
  background: #1B1F36;
  box-shadow: inset 2px 4px 8px #171A27;
  border-radius: 8px;
  border: 0;
  height: 35px;
  font-size: 14px;
  color: #CDB5B5;
}

.form-input-text {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.typeId {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.left-typeId {
  display: flex;
  flex-direction: column;
}

.text-type {
  display: flex;
  gap: 67px;
  align-items: baseline;
}

.title-type {
  font-size: 14px;
  color: #CDB5B5;
}

.pass-txt {
  font-size: 12px;
  position: relative;
}

.pass-txt:after{
  content: "";
  position: absolute;
  width: 55%;
  background-color: #26FFFF;
  height: 3px;
  bottom: 0;
  top: 20px;
  left: 0;
  border-radius: 20px;
}

.idcart-txt {
  font-size: 12px;
  color: #CDB5B5;
}

.text-caption {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.txt-one {
  font-size: 14px;
  color: #CDB5B5;
}


</style>