<template>
  <div class="order-book">
    <div class="padding-top-div">
      <div class="header-order-book">
        <a :class="{'selected' : select === 'book'}" @click="select = 'book'">Order Book</a>
        <a :class="{'selected' : select === 'history'}" @click="select = 'history'">Trade History</a>
      </div>
      <div class="tables">

        <div class="right-table">
          <table class="table table-borderless">
            <tr>
              <th>Price</th>
              <th>Amount</th>
              <th>Total</th>
            </tr>
            <tr v-for="d in data" :key="d[0]">
              <td :class="{'green' : 'green'}">{{ d.Price }}</td>
              <td>{{ d.Amount }}</td>
              <td>{{ d.Total }}</td>
            </tr>
          </table>
        </div>

        <div class="border"></div>

        <div class="left-table">
          <table class="table table-borderless">
            <tr>
              <th>Price</th>
              <th>Amount</th>
              <th>Total</th>
            </tr>
            <tr v-for="d in data" :key="d[0]">
              <td :class="{'red' : 'red'}">{{ d.Price }}</td>
              <td>{{ d.Amount }}</td>
              <td>{{ d.Total }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="footer">
      <span class="val">54,765.9</span>
      <img src="../../assets/trade/red-arrow.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Order-Book",
  data() {
    return {
      select: 'book',
      data: [
        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: '',
          color: 'red'
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: '',
          color: 'red'
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: '',
          color: 'green'
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: '',
          color: 'green'
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          Expiration: '4/5/21  05:12',
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: '',
          color: 'green'
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.red {
  color: #FA0640;
}

.green {
  color: #06F148;
}

.order-book {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;

  .border {
    border: 1px solid #796C6C;
  }

  .padding-top-div {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 20px;
  }

  .header-order-book {
    display: flex;
    flex-grow: 1;
  }

  .tables {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;

    .table td, .table th {
      border: none;
    }


    .table th, .table td {
      vertical-align: middle;
      padding: 5px !important;
    }

    .left-table {
      flex-grow: 1;
      flex-basis: 100px;
    }

    .right-table {
      flex-grow: 1;
      flex-basis: 100px;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(75, 80, 90, 0.32);
    border-radius: 0 0 20px 20px;
    padding: 20px;
    font-size: large;
    color: #FA0640;
  }

}

a {
  font-size: small;
  margin: 0 30px 0 0;
  text-decoration: none;
  color: #AFAFAF;
  opacity: 0.7;
  position: relative;
  cursor: pointer;

  &.selected {
    font-size: medium;
    color: white;
    opacity: 1;

    &:after {
      content: "";
      position: absolute;
      width: 50%;
      background-color: #26FFFF;
      height: 3px;
      bottom: 0;
      top: 25px;
      left: 0;
      border-radius: 20px;

    }
  }
}

@media only screen and (max-width: 365px) {
  .border {
    display: none;
  }
}
</style>