<template>
  <select class="select-custom" :class="color" >
    <option  v-for="i in items" :key="i">{{i}}</option>
  </select>
</template>

<script>
export default {
  name: "Theselected",
  props:['color'],
  data(){
    return{
      items:['Market1','Market2','Market3','Market4',],
      isActive :true,
    }
  }
}
</script>

<style scoped>
.select-custom{
  background-color: #1B1F36;
  border-radius: 8px;
  border: 0;
  padding: 5px;
  margin-left: 5px;
}

.green{
  color: #06F148;
}

.red{
  color: #FA0640;
}

.solid{
  color: white;
}
</style>