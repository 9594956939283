<template>
  <div class="login-header" :class="{'between' : ['Login','SingUp'].includes($route.name)}">
    <span :class="{'selected' : selected === 'header1' ,'unselected' :selected !== 'header1'}" v-if="header1">{{header1}}</span>
    <span :class="{'selected' : selected === 'header2' ,'unselected' :selected !== 'header2'}" v-if="header2">{{header2}}</span>
  </div>
</template>

<script>
export default {
  name: "HeaderLoginForm",
  props:['header1','header2','selected'],

  data(){
    return{

    }
  },
}
</script>

<style lang="scss"  scoped>
/*---------------------------------header login-------------------------------------------*/
.login-header{
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  justify-content: center;
}

.selected {
  font-size: 18px;
  color: white;
  font-weight: 500;
}

.between{
  justify-content: space-between;
}

.unselected {
  font-size: 14px;
  color: #7C7C7C;
}
</style>