import Vue from 'vue'
import App from './App.vue'
import  './styles/bootstrap.css'
import  './styles/style.css'
import  router from "./libs/router"
import VueApexCharts from 'vue-apexcharts'
import  './libs/state'
import InlineSvg from 'vue-inline-svg';

Vue.component('inline-svg', InlineSvg);

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
