<template>
  <div class="robot-check">
    <input type="checkbox" class="form-control check-me">
    <span class="lbl-check">Im not a robot</span>
  </div>
</template>

<script>
export default {
  name: "IAmNotRobot"
}
</script>

<style scoped>
/*---------------------------------robot-check input-------------------------------------------*/
.check-me{
  width: 8% !important;
  border:0 ;
}
.robot-check{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  background-color: #C4C4C4;
  padding: 10px;
  margin-bottom: 30px;
}

.lbl-check{
  font-size: 16px;
  color: #292828;
  line-height: 2.5;
  margin-left: 15px;
}

</style>