<template>
  <div class="slid-why">
    <div class="flx-container">
      <h1>Why Warex?</h1>
    </div>

    <div class="flex-container">

      <div class="item-flex" v-for="d in flexitems" :key="d[0]">
        <div class="flx">
          <div class="pic-flx">
            <img :src="require('../../assets/choise/'+d.pic)" :alt="d.name">
          </div>
          <div class="text-flx">
            <p>
              {{ d.text }}
            </p>
          </div>
        </div>
        <div class="bottom">
          {{ d.footertext }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

      flexitems: [

        {
          pic: "filled.png",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et\\n\" +\n" +
              "  dolore magna aliqua",
          footertext: "Low Transaction Fees"
        },

        {
          pic: "time.png",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et\\n\" +\n" +
              "  dolore magna aliqua",
          footertext: "Low Transaction Fees"
        },

        {
          pic: "convercation.png",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et\\n\" +\n" +
              "  dolore magna aliqua",
          footertext: "Low Transaction Fees"
        },

        {
          pic: "secure 1.png",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et\\n\" +\n" +
              "  dolore magna aliqua",
          footertext: "Low Transaction Fees"
        },

      ],

    }
  }
}
</script>

<style scoped>

.slid-why {
  width: 80%;
  align-self: flex-start;
  background-color: #040F26;
  border-radius: 0 50px 50px 0;
  height: auto;
  display: flex;
  position: relative;
  bottom: 70px;
  padding: 50px;
  color: white;
  flex-direction: column;
}

.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 100%;
  align-self: center;
  justify-content: space-between;
}

.item-flex {
  flex-basis: 230px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

}

.pic-flx {
  display: flex;
  flex-basis: 40%;
  flex-direction: row;
  flex-grow: 1;
  height: min-content;
}

.text-flx {
  flex-basis: 60%;
  display: flex;
  flex-direction: row;
  flex-grow: 3;
  font-size: 1.3rem;
}

.bottom {
  flex-basis: 100%;
  display: flex;
  flex-direction: row;
  font-size: 1.6rem;
}

.flx {
  flex-basis: 100%;
  flex-direction: row;
  display: flex;
}

.flx-container {
  display: flex;
  flex-basis: 100%;
  margin-bottom: 75px;
}
</style>