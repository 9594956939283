<template>
  <div class="personal-info">
    <div class="padding">
      <div class="header">
        <p class="title">Personal Info</p>
        <p class="edit">If the info is wrong or incomplete <router-link to="VerificatIonEdite">Edit it</router-link></p>
      </div>

      <div class="form-select">
          <TheSuccessinput type="text" placeholder="Name" logo="success"/>
      </div>

      <div class="form-input-text">
        <TheSuccessinput type="text" placeholder="Name" logo="success"/>
        <TheSuccessinput type="text" placeholder="Family" logo="success"/>
      </div>

      <div class="header">
        <p class="title">ID Verification</p>
      </div>

      <div class="typeId">
        <div class="left-typeId">
          <p class="title-type">Type of ID</p>
          <div class="text-type">
            <p class="pass-txt">Passport</p>
            <p class="idcart-txt">Government-issued Card</p>
          </div>
        </div>
        <div class="right-typeId">
          <TheSuccessinput type="text" placeholder="Family" logo="success"/>
        </div>
      </div>
      <div class="SuccessPicture">
      <TheSuccessPicture/>
      </div>
    </div>
  </div>
</template>

<script>
import TheSuccessinput from "../Tools/TheSuccessinput";
import TheSuccessPicture from "../Tools/TheSuccessPicture"
export default {
  name: "VerificatIonid",
  components: {TheSuccessinput , TheSuccessPicture},
  data() {
    return {
      options: ['Iran', 'Iraq', 'Germany'],
    }
  }
}
</script>

<style scoped>
.personal-info {
  display: flex;
  flex-direction: column;
  background: #1E2A4B;
  border-radius: 29px;
}

.edit{
  font-size: 12px;
  color: #CDB5B5;
}

.padding{
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 20px auto;
  gap: 20px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  flex-wrap: nowrap;
}

.title {
  font-size: 14px;
  color: #CDB5B5;
}

.form-select {
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.form-input-text {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.typeId {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

.left-typeId {
  display: flex;
  flex-direction: column;
}

.text-type {
  display: flex;
  gap: 20px;
  align-items: baseline;
}

.title-type {
  font-size: 14px;
  color: #CDB5B5;
}

.pass-txt {
  font-size: 12px;
  position: relative;
}

.pass-txt:after{
  content: "";
  position: absolute;
  width: 55%;
  background-color: #26FFFF;
  height: 3px;
  bottom: 0;
  top: 20px;
  left: 0;
  border-radius: 20px;
}

.idcart-txt {
  font-size: 12px;
  color: #CDB5B5;
}

.text-caption {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.txt-one {
  font-size: 14px;
  color: #CDB5B5;
}


</style>