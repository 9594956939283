<template>
  <div class="send-code">
    <TheCodeInput/>
  </div>
</template>

<script>

import TheCodeInput from "../../Tools/TheCodeInput";
export default {
  name: "Resivecode",
  components: {TheCodeInput},
}
</script>

<style scoped>
.send-code{
  margin-bottom: 30px;
}


</style>