<template>
  <div class="main">
    <div class="header-terms">
      <h1 class="title-header">{{ title }}</h1>
      <small class="small-header">{{ date }}</small>
      <carousel class="" :items=5 :dots=false :nav="true" :navText="['>' , '<']" :responsive="{0:{items:2,nav:true},600:{items:5,nav:true}}">
        <span :href="items" class="item" v-for="i in items" :key="i"><a class="link-terms" href="#">{{ i }}</a> </span>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  name: "TheHeaderTerms",
  props: ['title', 'date' , 'items'],
  data() {
    return {
    }
  },

  components: { carousel },

}
</script>

<style scoped>

.main {
  background-color: #02163E;
}

.header-terms {
  background-color: #02163E;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding: 30px 0;
}

.title-header{
  color: white;
}

.small-header{
  color: white;
  font-size: 14px;
  margin-bottom: 50px;
}

.link-terms{
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.item :focus{
  border-bottom: 2px solid cornflowerblue;
}



</style>