import { render, staticRenderFns } from "./Theinsertimage.vue?vue&type=template&id=86fa6332&scoped=true&"
import script from "./Theinsertimage.vue?vue&type=script&lang=js&"
export * from "./Theinsertimage.vue?vue&type=script&lang=js&"
import style0 from "./Theinsertimage.vue?vue&type=style&index=0&id=86fa6332&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86fa6332",
  null
  
)

export default component.exports