<template>
  <div class="main-total">
    <div class="padding">
      <div class="left-total">
        <span class="title">Total Balance</span>
        <span class="number">13490.38</span>
        <span class="available"> Available  13490.38</span>
        <div class="btn-me">
          <button class="btn-1 btn">Withdraw</button>
          <button class="btn-2 btn">Deposit</button>
        </div>
      </div>
      <div class="right-total">
        <apexchart width="88%" type="donut" :options="options" :series="series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Total",
  data() {
    return {
      options: {
        colors: ['#0CB746', '#0029FE', '#1396F5', '#FE8900'],
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]

      },
      //this data apex chart
      series: [25, 25, 25, 25,]
    }
  }
}
</script>

<style scoped>
.main-total {
  display: flex;
  flex-wrap: wrap;
  background-color: #011845;
  border-radius: 16px;
}

.left-total {
  flex-grow: 1;
  flex-basis: 100px;
  flex-direction: column;
  display: flex;
}

.right-total {
  flex-grow: 1;
  flex-basis: 100px;
}

.padding {
  width: 95%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
}
.btn-me {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 50px;
}

.btn-1 {
  background-color: #FF5107;
  border-radius: 10px;
  border: none;
  color: white !important;
  flex-basis: 117px;
  padding: 5px 20px;
  text-decoration: none;
  font-size: 14px;
  margin-right: 10px;
}

.btn-2 {
  background-color: #F0CB06;
  border-radius: 10px;
  border: none;
  color: white !important;
  flex-basis: 117px;
  padding: 5px 20px;
  text-decoration: none;
  font-size: 14px;
}

.title {
  font-size: 16px;
  margin-bottom: 10px;
}

.number {
  font-size: 18px;
}

.available {
  font-size: 16px;
  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .right-total {
    border-left: 0;
  }
}
</style>