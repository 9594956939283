<template>
  <div class="media">
    <div class="text">
      <span>Follow us on social media:</span>
    </div>

    <div class="icon">
      <a :href="twitter">
        <img src="../../assets/About/twitter.png" alt="">
      </a>
      <a :href="telegram">
        <img src="../../assets/About/telegram.png" alt="">
      </a>
      <a :href="facebook">
        <img src="../../assets/About/facebook.png" alt="">
      </a>
      <a :href="inestageram">
        <img src="../../assets/About/inestageram.png" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "sochialmedia",
  data() {
    return {
      twitter: '',
      telegram: '',
      facebook: '',
      inestageram: ''
    }
  }
}
</script>

<style scoped>
.media {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
  margin:30px auto;
}

.text{
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.text span{
  font-size: 16px;
}
.icon{
  flex-grow: 5;
  justify-content: space-around;
  display: flex;
}

</style>