<template>
  <div id="app">
    <app-navbar v-if="width >= 1200"></app-navbar>
    <app-mobilenavbar v-if="width <= 992"></app-mobilenavbar>
    <router-view></router-view>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Navbar from "./components/shared/navbar/navbar";
import Footer from "./components/shared/footer/Thefooter";
import Mobilenavbar from "./components/shared/navbar/Mobilenavbar";
export default {
  "name": 'App',
  "data"() {
    return {
      "width": 0
    }
  },



  "mounted"() {
    this.width = screen.width
  },

  "components": {
    "appNavbar": Navbar,
    "appFooter": Footer,
    "appMobilenavbar": Mobilenavbar,

  }
}

</script>

<style>
#app{
  max-width: 1364px;
  margin: 0 auto;
}
</style>
