<template>
  <div class="user-button">
    <div @click="show =!show" class="btn-user">
      <span>Hadi Moradi</span>
    </div>
    <div v-if="show" class="navbar-user">
      <div class="items">
        <div class="item">
          <img src="../../../assets/trade/item-1.png" alt="">
          <router-link to="Dashbord">Dashboard</router-link>
        </div>
        <div class="item">
          <img src="../../../assets/trade/item-2.png" alt="">
          <router-link to="Trade">Trading</router-link>
        </div>
        <div class="item">
          <img src="../../../assets/trade/item-3.png" alt="">
          <router-link to="KYC">Verification</router-link>
        </div>
        <div class="item">
          <img src="../../../assets/trade/item-4.png" alt="">
          <router-link to="">Referral</router-link>
        </div>
      </div>
      <div class="footer-nav">
        <div class="item">
          <img src="../../../assets/trade/exit.png" alt="">
          <router-link to="/auth/Login">Log out</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterNav",
  data(){
    return{
      show:false
    }
  }
}
</script>

<style lang="scss" scoped>

.user-button {
  display: flex;
  flex-direction: column;
  order: 1;
  flex-grow: 2;
  position: relative;
  z-index: 2;

  .btn-user {
    background-color: #02163E;
    width: 100%;
    color: #CDB5B5;
    font-size: medium;
    align-items: start;
    display: flex;
    justify-content: space-between;
    background-image: url("../../../assets/trade/arrow-butto.png");
    background-position: 90% 50%;
    background-repeat: no-repeat;
    padding: 10px;
    border-radius: 10px 20px 20px 10px;
    cursor: pointer;
    z-index: 1;

  }

  .navbar-user {
    position: absolute;
    z-index: 0;
    background-color: white;
    height: 210px;
    width: 100%;
    top: 80%;
    right: 34%;
    border-radius: 40px 0 20px 20px;
    display: flex;
    flex-direction: column;

    .footer-nav {
      height: 20%;
      background: #6D727C52;
      border-radius: 0 0 20px 20px;
      position: relative;
      top: 15%;
      padding: 0 15px;
      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 25px;
        margin-top: 15px;

        a {
          font-size: small;
          color: #1B1919;
          font-weight: 550;
          margin-left: 10px;
          text-decoration: none;
        }
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 15px;
        margin-top: 15px;
        a {
          font-size: small;
          color: #1B1919;
          font-weight: 550;
          margin-left: 10px;
          text-decoration: none;
        }
      }
    }
  }
}

</style>