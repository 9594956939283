<template>
  <div class="main-crypto">
    <div class="header">
      <span class="title">Your Crypto</span>
      <span><a class="see-all" href="#">See All</a></span>
    </div>
    <div class="main">
      <TheWallet v-for="(item,index) in data" :key="index.name" :item="item"/>
    </div>
  </div>
</template>

<script>
import TheWallet from "../Tools/TheWallet";
export default {
  name: "cyrpto",
  components: {TheWallet},
  data() {
    return {
      data: [

        {
          name: 'Etherum',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'etherum'
        },

        {
          name: 'Ripple',
          btc: 0.0234,
          usd: 1493.34,
          availabebalance: 0.2,
          logo:'ripple'
        },
      ]
    }
  }
}
</script>

<style scoped>
.main-crypto{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  background-color: #313754;
  border-radius: 16px;
  overflow: hidden;
}
.header{
  display:flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
}
.title{
  font-size: 16px;
}
.see-all{
  color: whitesmoke;
  font-size: 12px;
  text-decoration: none;
}
.main{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>