<template>
  <CodeInput  :loading="false" class="input" v-on:change="onChange" v-on:complete="onComplete" color="color" disable="disable" />
</template>

<script>
import CodeInput from "vue-verification-code-input";

export default {
  props:['color' , 'disable'],
  data(){
    return{

    }
  },
  name: "TheCodeInput",
  components: {
    CodeInput
  },

  methods: {
    onChange(v) {
      console.log("onChange ", v);
    },
    onComplete(v) {
      console.log("onComplete ", v);
    }
  }
}
</script>

<style>
.react-code-input-container{
  text-align: center;
}

.react-code-input  input{
  width: 40px !important;
  height: 40px !important;
  background: #1B1F36;
  box-shadow: inset 2px 4px 8px #171A27;
  border-radius: 8px !important;
  border: 0 !important;
  margin-right: 10px;
  position: relative;

}

.react-code-input input ::after{
  content: "";
  background-color: #FCFCFC;
  height: 2px;
  width: 5px;
  position: absolute;
}

</style>