<template>
  <div class="email-input-div">
    <input  :type="type"  class="email-input form-control" @keypress="VisiblePassword">
    <img v-if="icon" :src="require('../../assets/Login/'+icon)" alt="" class="email-tag" @click="changeType">
    <p class="placeholder">{{placeholder}}</p>
  </div>
</template>

<script>

const a = {
  email : 'notfication.png',
  password : 'privecy.png',
  passwordVisible : 'eaye.png',
  success : 'check.png'
}

export default {
  name: "TheInput",
  props:['type','placeholder','logo'],
  data(){
    return{

    }
  },
  computed:{
    icon(){
      return a[this.logo]
    }
  },

  methods:{
    VisiblePassword(){
      if (this.logo === 'password'){
        this.logo = 'passwordVisible';
      }
    },

    changeType(){
      if (this.type === 'password'){
        this.type = 'text'
      }

      else if (this.type === 'text'){
        this.type = 'password'
      }
    }
  }

}
</script>

<style scoped>
.email-input-div {
  display: flex;
  margin-bottom: 30px;
}


.email-input {
  border: none;
  background: #1B1F36;
  color: #968888;
  font-size: 12px;
  padding: 5px 5px 5px 100px;
  border-radius: 8px;
  box-shadow: 2px 4px 8px 0 #171A27 inset;
  position: relative;
}


.email-tag {
  height: 20px;
  position: absolute;
  margin: 7px 10px;
  cursor: pointer;
}

.placeholder{
  color: #968888;
  font-size: 12px;
  position: relative;
  right: 42px;
  top: 10px;

}
</style>