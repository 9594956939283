<template>
  <div class="main-notification">
    <div class="notification-center">
      <div class="notification-header">
        <ul class="notification-tab">
          <li><a :class="{'selected' : select === 'notif'}" @click="select = 'notif'">Notifications</a></li>
          <li><a :class="{'selected' : select === 'trade'}" @click="select = 'trade'"  href="#">Trade Notification</a></li>
          <li><a :class="{'selected' : select === 'system'}" @click="select = 'system'"  href="#">System Messages</a></li>
          <li><a :class="{'selected' : select === 'alert'}" @click="select = 'alert'" href="#">Alert News</a></li>
        </ul>
      </div>
      <div class="bottom-header">
        <ul class="notification-read">
          <li><a :class="{'selected' : show === 'all'}" @click="show = 'all'" href="#">All</a></li>
          <li><a :class="{'selected' : show === 'unread'}" @click="show = 'unread'" href="#">Unread </a></li>
        </ul>
      </div>
      <div class="log">
        <log v-for="notification in notifications" :key="notification.title"
             :title="notification.title"
             :logo="notification.logo"
             :date="notification.date"
             :log="notification.log"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Log from "./Log";

export default {
  name: "Notification",
  components: {Log},
  data() {
    return {
      notifications: [
        {title : 'Login attempted from new IP1', logo:'enter',  date: '4/5/21  05:12',   log: 'The system has detected that your account is logged in from an unused IP address.The system has detected that your account is logged in from an unused IP addressThe system has detected that your account is logged in from an unused IP addressThe system has detected that your account is logged in from an unused IP address'},
        {title : 'Login attempted from new IP2', logo:'newip',  date: '4/5/21  05:12',   log: 'The system has detected that your account is logged in from an unused IP address.The system has detected that your account is logged in from an unused IP addressThe system has detected that your account is logged in from an unused IP addressThe system has detected that your account is logged in from an unused IP address'},
        {title : 'Login attempted from new IP3', logo:'new',    date: '4/5/21  05:12',   log: 'The system has detected that your account is logged in from an unused IP address.The system has detected that your account is logged in from an unused IP addressThe system has detected that your account is logged in from an unused IP addressThe system has detected that your account is logged in from an unused IP address'}
      ],
      select : 'notif',
      show : 'all'
    }
  }
}
</script>

<style lang="scss" scoped>
.main-notification {
  background-color: #02163E;
  display: flex;
}

.notification-center {
  width: 80%;
  justify-content: center;
  display: flex;
  margin: 20px auto;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  background-color: #1E2A4B;
  color: white;
  border-radius: 20px;
  padding:20px 40px;
}

.notification-tab li {
  list-style: none;
  display: inline-flex;

}

.notification-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
  gap: 20px;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.notification-read li {
  list-style: none;
  display: inline-flex;

}


.bottom-header {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.log {
  display: flex;
  flex-direction: column;
  width: 100%;
}


 a{
   font-size: small;
   margin: 30px 30px 0 0;
   text-decoration: none;
   color: white;
   position: relative;

  &.selected{
    font-size: large;
    &:after{
      content: "";
      position: absolute;
      width: 50%;
      background-color: #26FFFF;
      height: 3px;
      bottom: 0;
      top: 25px;
      left: 0;
      border-radius: 20px;

    }
  }
 }
</style>