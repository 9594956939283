<template>
  <div class="main">
    <div class="order-history">
      <div class="header">
        <div class="tittle-text">
          <span class="tittle">Order History</span>
        </div>
        <div class="filter">
          <div class="buy-sell">
            <a :class="{'link-me':  active}" @click="active = true" href="#">Buy</a>
            <a :class="{'link-me': !active}" @click="active = false" href="#">Sell</a>
          </div>
          <div class="date">
            <span>Date</span>
            <div class="input-date">
              <span class="spn-date">4/05/21</span>
              -
              <span class="spn-date">4/05/21</span>
              <img src="../../assets/History/calender.png" alt="">
            </div>
          </div>
          <div class="order-type">
            <span class="spn-order">
              Order Type
            </span>
            <Theselected :color="active? 'green':'red'"/>
          </div>
          <div class="coin">
            <span class="spn-coin">
             Coin
            </span>
            <Theselected success="solid"/>
          </div>
          <div class="btn-me">
            <ThecustomButton value="Search"/>
          </div>
        </div>
      </div>

      <div class="table">
        <table class="table table-borderless">
          <tr>
            <th>Order Time</th>
            <th>Type</th>
            <th v-if="width > 768">Pair</th>
            <th>Amount</th>
            <th v-if="width > 768">Price</th>
            <th v-if="width > 768">Fee</th>
            <th>Total</th>
            <th v-if="width > 768">Status</th>
          </tr>

          <tr v-for="d in data" :key="d[0]">
            <td>{{ d.OrderTime }}</td>
            <td :class="{'red':  !active , 'green' : active}">{{ d.Type }}</td>
            <td v-if="width > 768">{{ d.Pair }}</td>
            <td>{{ d.Amount }}</td>
            <td v-if="width > 768">{{ d.Price }}</td>
            <td v-if="width > 768">{{ d.Fee }}</td>
            <td>{{ d.Total }}</td>
            <td v-if="width > 768" v-show="d.Status === true" >
              <img src="../../assets/History/success-chart.png" alt="">
            </td>
            <td v-if="width > 768" v-show="d.Status === false" >
              <img src="../../assets/History/block.png" alt="">
            </td>
          </tr>

        </table>
      </div>


    </div>
  </div>
</template>

<script>
import Theselected from "../Tools/Theselected";
import ThecustomButton from "../Tools/ThecustomButton";

export default {
  name: "OrderHistory",
  components: {ThecustomButton, Theselected},
  data() {
    return {
      width: 0,
      active: true,
      data: [
        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Fee:0.05,
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: true,
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Fee:0.05,
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: false,
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Fee:0.05,
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: true,
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Fee:0.05,
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: false,
        },

        {
          Pair: 'BTC/USDT',
          Type: 'Market',
          OrderTime: '4/5/21  05:12',
          Fee:0.05,
          Amount: '0.003',
          Price: '54,765.9',
          Total: '54,765.9',
          Status: true,
        },
      ],
      options: {
        colors: ['#1CFF77'],
        dataLabels: {
          enabled: false,
        },

        chart: {
          offsetX: -30
        }

      },
      series: [100],

    }
  },

  "mounted"() {
    this.width = screen.width
  },

}
</script>

<style scoped>
.red{
  color: #FA0640;
}

.green{
  color: #06F148;
}
.main {
  display: flex;
  justify-content: center;
  background-color: #040F26;
  align-items: center;
}

.order-history {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #1E2A4B;
  width: 80%;
  border-radius: 16px;
  margin: 50px 0;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px;
}

.tittle {
  font-size: 18px;
  color: white;
}

.filter {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  font-size: 14px;
  color: white;
  justify-content:center;
  flex-wrap: wrap;
  gap: 50px 70px;

}


.date {
  display: flex;
  flex-direction: row;
}

.input-date {
  background-color: #1B1F36;
  border-radius: 8px;
  margin-left: 10px;
  padding: 5px;
  position: relative;
  bottom: 5px;
  opacity: 0.7;
}

a {
  color: white;
  opacity: 0.7;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  margin-right: 20px;
}

.link-me:checked {

}

.link-me:hover {
  cursor: pointer;
}

.link-me {
  opacity: 1;
  font-size: 16px;
}

.link-me::after {
  content: "";
  position: absolute;
  width: 55%;
  background-color: #26FFFF;
  height: 3px;
  bottom: 0;
  top: 20px;
  left: 0;
  border-radius: 20px;
}

.table{
  width: 90%;
  max-width: 100%;
  background-color: transparent;
  margin: 10px auto;

}

table {
  background-color: transparent !important;
  color: white;
  font-size: 12px;
  margin-top: 20px;
  position: relative;
  table-layout: fixed;
  padding-left: 53px;
}

table th {
  border: none;
  color: #EDEDED !important;
  font-weight: 100;
  padding: 0;
}

.table td {
  border: none;
}


.table th, .table td {
  vertical-align: middle;
  padding: 5px !important;
}

@media (max-device-width: 420px) {
  .filter {
    justify-content: flex-start;
  }
}

/*.date{*/


/*}*/

/*.order-type{*/


/*}*/

/*.coin{*/


/*}*/

/*.btn-me{*/


/*}*/


</style>