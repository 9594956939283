<template>
  <div class="main-dashbord">
    <div class="rowColection">
      <AccountOverview/>
      <SecuritySettings/>
    </div>

    <div class="rowColection">
      <MyAssets/>
      <order-history history="history"/>
    </div>

    <div class="rowColection">
      <invite/>
      <referrals/>
    </div>

  </div>
</template>

<script>
import AccountOverview from "./AccountOverview";
import SecuritySettings from "./SecuritySettings";
import MyAssets from "./MyAssets";
import OrderHistory from "./OrderHistory";
import Invite from "./invite";
import Referrals from "./Referrals";

export default {
  name: "Dashbord",
  components: {Referrals, Invite, OrderHistory, MyAssets, SecuritySettings, AccountOverview}
}
</script>

<style scoped>
.main-dashbord {
  display: flex;
  flex-direction: row;
  background: #02163E;
  flex-wrap: wrap;
}

.rowColection {
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
}

</style>