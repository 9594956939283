<template>
  <div class="main-modal" @click.self="$emit('close')">
    <div class="wallet-modal">
      <img class="bitcoin" :src="require('../../assets/Wallet/'+icon)" alt="">
      <div class="header-modal">{{ item.name }} Withdrawal</div>
      <div class="row-1">
        <div class="item-1">
          <p>Withdraw Amount</p>
          <TheInput type="text" placeholder="BTC"/>
          <p>Network Fee: {{ item.btc }}</p>
        </div>
        <div class="item-2">
          <p>Withdraw to</p>
          <TheInput type="text" placeholder="Enter Address here"/>
          <p class="alert-me">Invalid address may result in the loss of your assets.
          </p>
        </div>
      </div>

      <div class="row-2">
        <div class="item-1">
          <p>Verification</p>
          <TheInput type="text" placeholder="2FA"/>
        </div>
        <div class="item-2">
          <ThecustomButton value="Withdraw"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheInput from "../Tools/TheInput";
import ThecustomButton from "../Tools/ThecustomButton";

const a = {
  bitcoin: 'bitcoin.png',
  etherum: 'etherum.png',
  ripple: 'ripple.png',
}
export default {
  name: "WalletModal",
  components: {ThecustomButton, TheInput},
  props: ['item'],

  computed: {
    icon() {
      return a[this.item.logo]
    }
  },
}
</script>

<style lang="scss" scoped>
.main-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: fixed;
  inset: 0;
  background: #1322468f;

  .bitcoin {
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-15px, -20px);
  }

  .wallet-modal {
    background-color: #081734;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    height: auto;
    position: relative;
    gap: 20px;
    width: auto;

    .header-modal {
      display: flex;
      justify-content: center;
      color: #CDB5B5;
      font-size: medium;
    }

    .row-1 {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .item-1 {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 100px;
        color: white;
      }

      .item-2 {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        flex-basis: 200px;
        color: white;
        .alert-me{
          color: #FA0640;
        }
      }
    }

    .row-2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .item-1 {
        display: flex;
        flex-direction: column;
        flex-basis: 170px;
        color: white;
      }

      .item-2 {
        display: flex;
        flex-direction: column;
        flex-basis: 200px;
        color: white;
        height: 40px;
        max-height: 40px;
        .alert-me{
          color: #FA0640;
        }
      }
    }
  }
}
</style>