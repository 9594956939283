<template>
  <div class=" col slid">
    <div class="col container-flex text">
      <div>
        <h1 class="textme1">Trade Safely at WAREX</h1>
        <small class="small1">Trade Safely at WAREX</small>
      </div>
      <div class="textme">
        <input type="text" class="form-control"/>
        <button class="btn btnme">Register</button>
      </div>
    </div>
    <div class="container-flex rowme numbers">
      <div class="num1">
        <p class="number">{{num1}}</p>
        <small class="small2">{{txt1}}</small>
      </div>
      <div class="num2">
        <p class="number">$ {{num2}} +</p>
        <small class="small2">{{txt2}}</small>
      </div>
      <div class="num3">
        <p class="number">{{num3}}</p>
        <small class="small2">{{txt3}}</small>
      </div>
      <img class="posimg" src="../../assets/slid/Backgroundpic.png" alt="">
    </div>

  </div>
</template>

<script>
export default {
  name: "home",
  data(){
    return{
      txt1:"Trades Count",
      txt2:"Cryptocurrency Exchanged",
      txt3:"Active Customers",
      num1:"30,000",
      num2:"300,000",
      num3:"30,000",
    }
  }
}
</script>

<style scoped>
.textme{
  margin-top: 30px;
  width: 50%;
  display: flex;
}

.posimg{
  position: relative;
  bottom:120px;
}
.numbers {
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
}

.number {
  font-size: 35px;
  color: white;
  text-align: center;
  flex-wrap: wrap;
}

.small2 {
  font-size: 19px;
  color: white;
  text-align: left;
}

.form-control {
  border: 0;
  height: 36px;
  background: #1B1F36;
  color: white;
}

.text {
  flex-basis: 258px;
  justify-content: end;
  width: 50%;
  align-items: center;
}

.slid {
  background-color: #02163e;
  background-image: url("../../assets/slid/Background.png");
  width: 100%;
  display: flex;
}

.textme1 {
  color: #f1f1f1;
}

.small1 {
  color: #f1f1f1;
}

.btnme {
  position: relative;
  left: 0;
  bottom: 0;
  border-radius: 0 10px 10px 0;
  background-color: #1cbfe2;
  color: white;
  border: 0;
  flex-basis: 100px;
}

.btnme:hover {
  background: linear-gradient(98.51deg, #0F74EB -1.56%, #1CBFE2 110.22%);
}

@media screen and (max-width: 768px) {
  .text {
    width: 100%;
  }

  .numbers{
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }

  .posimg{
    bottom: 0;
    margin-top: 50px;
  }

  .nav-3 ul{
    margin-left: 29px;
  }
}

@media screen and (max-width: 573px) {
 .textme{
   width: 91%;
 }

}
</style>