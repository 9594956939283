<template>
<div class="auth">
  <div class="box">
    <router-view/>
  </div>
</div>
</template>

<script>
export default {
  name: "Auth"
}
</script>

<style scoped>

.auth{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #040F26;
}

.box {
  display: flex;
  background: rgba(49, 55, 84, 0.6);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 40px;
  margin: 40px 0;
}

</style>